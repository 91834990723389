import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

// Components
import ServiceTitleBar from "../../components/nav/ServiceTitleBar";
import ServiceAdmin from "./ServiceAdmin";
import ServiceManager from "./ServiceManager";
import ServiceRep from "./ServiceRep";
import LoadingBlock from "../../components/LoadingBlock";

class service extends Component {
  render() {
    const { auth, profile } = this.props;
    if (!auth.uid) return <Redirect to="/login" />;

    if (!profile.isLoaded) {
      return <LoadingBlock />;
    }

    return (
      <div>
        <ServiceTitleBar />
        {profile.permission === "Admin" && (
          <ServiceAdmin
            showPass={profile.passwordaccess}
            showVideo={profile.videoaccess}
            showPricing={profile.pricingaccess}
            showDash={true}
          />
        )}
        {profile.permission === "Manager" && (
          <ServiceManager
            showPass={profile.passwordaccess}
            showVideo={profile.videoaccess}
          />
        )}
        {profile.permission === "Service Rep" && (
          <ServiceRep
            showPass={profile.passwordaccess}
            showVideo={profile.videoaccess}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile
  };
};

export default connect(mapStateToProps)(service);
