import React, { Component } from "react";
import { connect } from "react-redux";
import {
  reformatTimestamp,
  reformatDate,
  formatUSD,
  formatGBP
} from "../../../../util/functions";

// Actions
import { updateQuote } from "../../../../store/actions/quoteActions";

// MUI Stuff
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class QuoteDialog extends Component {
  state = {
    id: this.props.rowId,
    serviceFacilityName: this.props.singleRow.serviceFacilityName,
    facilityContactName: this.props.singleRow.facilityContactName,
    facilityContactPhone: this.props.singleRow.facilityContactPhone,
    facilityContactEmail: this.props.singleRow.facilityContactEmail,
    facilityContactTitle: this.props.singleRow.facilityContactTitle,

    serviceFacilityAddress: this.props.singleRow.serviceFacilityAddress,
    serviceFacilityAddressCity: this.props.singleRow.serviceFacilityAddressCity,
    serviceFacilityAddressState: this.props.singleRow
      .serviceFacilityAddressState,
    serviceFacilityAddressZip: this.props.singleRow.serviceFacilityAddressZip,
    billingSame: this.props.singleRow.billingSame,
    billFacilityName: this.props.singleRow.billFacilityName,
    billFacilityAddress: this.props.singleRow.billFacilityAddress,
    billFacilityAddressCity: this.props.singleRow.billFacilityAddressCity,
    billFacilityAddressState: this.props.singleRow.billFacilityAddressState,
    billFacilityAddressZip: this.props.singleRow.billFacilityAddressZip,
    serviceTermStartDate: this.props.singleRow.serviceTermStartDate,
    serviceTerm: this.props.singleRow.serviceTerm,
    purchaseOrder: this.props.singleRow.purchaseOrder,
    closeDate: this.props.singleRow.closeDate,
    customerType: this.props.singleRow.customerType,
    customerStatus: this.props.singleRow.customerStatus,
    managedBy: this.props.singleRow.managedBy,
    contractType: this.props.singleRow.contractType
      ? this.props.singleRow.contractType
      : "",
    status: this.props.singleRow.status,
    closed_status: this.props.singleRow.closed_status
      ? this.props.singleRow.closed_status
      : "Silver",
    notes: this.props.singleRow.notes,
    notes_build: this.props.singleRow.notes_build,
    coverageSilverCost: this.props.singleRow.coverageSilverCost,
    coverageGoldCost: this.props.singleRow.coverageGoldCost,
    coveragePlatinumCost: this.props.singleRow.coveragePlatinumCost,
    coverageSilverCostGBP: this.props.singleRow.coverageSilverCostGBP,
    coverageGoldCostGBP: this.props.singleRow.coverageGoldCostGBP,
    coveragePlatinumCostGBP: this.props.singleRow.coveragePlatinumCostGBP,
    coverageSilverCostOriginal: this.props.singleRow.coverageSilverCostOriginal,
    coverageGoldCostOriginal: this.props.singleRow.coverageGoldCostOriginal,
    coveragePlatinumCostOriginal: this.props.singleRow
      .coveragePlatinumCostOriginal,
    coverageSilverCostGBPOriginal: this.props.singleRow
      .coverageSilverCostGBPOriginal,
    coverageGoldCostGBPOriginal: this.props.singleRow
      .coverageGoldCostGBPOriginal,
    coveragePlatinumCostGBPOriginal: this.props.singleRow
      .coveragePlatinumCostGBPOriginal,
    discountPercent: this.props.singleRow.discountPercent,
    microscopes: this.props.singleRow.microscopes,
    valueOverride: this.props.singleRow.valueOverride
      ? this.props.singleRow.valueOverride
      : "",

    additionalContacts: this.props.singleRow.additionalContacts,
    quotedFacility: this.props.singleRow.quotedFacility
      ? this.props.singleRow.quotedFacility
      : "",
    quotedFacilityWhyNo: this.props.singleRow.quotedFacilityWhyNo
      ? this.props.singleRow.quotedFacilityWhyNo
      : "",
    existingService: this.props.singleRow.existingService
      ? this.props.singleRow.existingService
      : "",
    penteroKinevo: this.props.singleRow.penteroKinevo
      ? this.props.singleRow.penteroKinevo
      : "",
    penteroKinevoQuoted: this.props.singleRow.penteroKinevoQuoted
      ? this.props.singleRow.penteroKinevoQuoted
      : ""
  };
  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };
  handleSelectChange = name => e => {
    this.setState({
      [name]: e.target.value
    });
  };
  handleCheckboxChange = e => {
    this.setState({
      [e.target.id]: e.target.checked
    });
  };
  handleTermStartDateChange = date => {
    this.setState({
      serviceTermStartDate: date
    });
  };
  handleCloseDateChange = date => {
    this.setState({
      closeDate: date
    });
  };
  handleSave = () => {
    // console.log(this.state);
    this.props.updateQuote(this.state);
    this.props.handleClose();
  };
  handleClose = () => {
    this.setState({
      status: this.props.singleRow.status
    });
    this.props.handleClose();
  };

  substringBetween = (s, a, b) => {
    var p = s.indexOf(a) + a.length;
    return s.substring(p, s.indexOf(b, p));
  };

  render() {
    const { singleRow, profile, rowId } = this.props;

    const coverageMap = {
      coverageBronze: "Bronze",
      coverageSilver: "Silver",
      coverageGold: "Gold",
      coveragePlatinum: "Platinum"
    };

    let quoteList = [];
    Object.keys(coverageMap).forEach(level => {
      if (!singleRow[level]) {
        return;
      }

      let key = level + "Cost";
      let value = "-";
      if (singleRow.userRegion == "United Kingdom") {
        const legacyKey = level + "CostGBP";
        if (singleRow.hasOwnProperty(legacyKey)) {
          value = formatGBP(singleRow[legacyKey]);
        } else {
          value = formatGBP(singleRow[key]);
        }
      } else {
        value = formatUSD(singleRow[key]);
      }

      quoteList.push({
        level: coverageMap[level],
        value: value
      });
    });

    return (
      <Dialog
        fullScreen
        open={this.props.open}
        onClose={this.props.handleClose}
        TransitionComponent={Transition}
        className="dialog--edit"
      >
        <AppBar className="modal-appbar">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={this.handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6">
              {singleRow.serviceFacilityName}
            </Typography>
            <Button autoFocus color="inherit" onClick={this.handleSave}>
              Save
            </Button>
          </Toolbar>
        </AppBar>
        <div className="update">
          <div className="update--column">
            <h2>Facility Information</h2>
            <div className="dialog--input">
              <TextField
                id="serviceFacilityName"
                label="Service Facility"
                variant="filled"
                onChange={this.handleChange}
                defaultValue={singleRow.serviceFacilityName}
              ></TextField>
            </div>
            <div className="dialog--input">
              <TextField
                id="facilityContactName"
                label="Facility Contact"
                variant="filled"
                onChange={this.handleChange}
                defaultValue={singleRow.facilityContactName}
              ></TextField>
            </div>
            <div className="dialog--input">
              <TextField
                id="facilityContactPhone"
                label="Phone"
                variant="filled"
                type="phone"
                onChange={this.handleChange}
                defaultValue={singleRow.facilityContactPhone}
              ></TextField>
            </div>
            <div className="dialog--input">
              <TextField
                id="facilityContactEmail"
                label="Email"
                variant="filled"
                type="email"
                onChange={this.handleChange}
                defaultValue={singleRow.facilityContactEmail}
              ></TextField>
            </div>

            <div className="dialog--input">
              <TextField
                id="facilityContactTitle"
                label="Facility Contact Title"
                variant="filled"
                type="text"
                onChange={this.handleChange}
                defaultValue={singleRow.facilityContactTitle}
              ></TextField>
            </div>

            <h3>Shipping Address</h3>
            <div className="dialog--input">
              <TextField
                id="serviceFacilityAddress"
                label="Service Address"
                type="text"
                variant="filled"
                onChange={this.handleChange}
                defaultValue={singleRow.serviceFacilityAddress}
              />
            </div>
            <div className="dialog--input">
              <TextField
                id="serviceFacilityAddressCity"
                label="City"
                type="text"
                variant="filled"
                onChange={this.handleChange}
                defaultValue={singleRow.serviceFacilityAddressCity}
              />
            </div>
            {singleRow.userRegion === "United Kingdom" ? (
              <div>
                <div className="dialog--input">
                  <TextField
                    id="serviceFacilityAddressZip"
                    label="Postcode"
                    type="text"
                    variant="filled"
                    onChange={this.handleChange}
                  />
                </div>
              </div>
            ) : (
              <div>
                <div className="dialog--input">
                  <FormControl variant="filled">
                    <InputLabel id="serviceFacilityAddressState-label">
                      State
                    </InputLabel>
                    <Select
                      id="serviceFacilityAddressState"
                      labelId="serviceFacilityAddressState-label"
                      variant="filled"
                      label="State"
                      value={this.state.serviceFacilityAddressState}
                      onChange={this.handleSelectChange(
                        "serviceFacilityAddressState"
                      )}
                    >
                      <MenuItem value="AL">Alabama</MenuItem>
                      <MenuItem value="AK">Alaska</MenuItem>
                      <MenuItem value="AZ">Arizona</MenuItem>
                      <MenuItem value="AR">Arkansas</MenuItem>
                      <MenuItem value="CA">California</MenuItem>
                      <MenuItem value="CO">Colorado</MenuItem>
                      <MenuItem value="CT">Connecticut</MenuItem>
                      <MenuItem value="DE">Delaware</MenuItem>
                      <MenuItem value="DC">District Of Columbia</MenuItem>
                      <MenuItem value="FL">Florida</MenuItem>
                      <MenuItem value="GA">Georgia</MenuItem>
                      <MenuItem value="HI">Hawaii</MenuItem>
                      <MenuItem value="ID">Idaho</MenuItem>
                      <MenuItem value="IL">Illinois</MenuItem>
                      <MenuItem value="IN">Indiana</MenuItem>
                      <MenuItem value="IA">Iowa</MenuItem>
                      <MenuItem value="KS">Kansas</MenuItem>
                      <MenuItem value="KY">Kentucky</MenuItem>
                      <MenuItem value="LA">Louisiana</MenuItem>
                      <MenuItem value="ME">Maine</MenuItem>
                      <MenuItem value="MD">Maryland</MenuItem>
                      <MenuItem value="MA">Massachusetts</MenuItem>
                      <MenuItem value="MI">Michigan</MenuItem>
                      <MenuItem value="MN">Minnesota</MenuItem>
                      <MenuItem value="MS">Mississippi</MenuItem>
                      <MenuItem value="MO">Missouri</MenuItem>
                      <MenuItem value="MT">Montana</MenuItem>
                      <MenuItem value="NE">Nebraska</MenuItem>
                      <MenuItem value="NV">Nevada</MenuItem>
                      <MenuItem value="NH">New Hampshire</MenuItem>
                      <MenuItem value="NJ">New Jersey</MenuItem>
                      <MenuItem value="NM">New Mexico</MenuItem>
                      <MenuItem value="NY">New York</MenuItem>
                      <MenuItem value="NC">North Carolina</MenuItem>
                      <MenuItem value="ND">North Dakota</MenuItem>
                      <MenuItem value="OH">Ohio</MenuItem>
                      <MenuItem value="OK">Oklahoma</MenuItem>
                      <MenuItem value="OR">Oregon</MenuItem>
                      <MenuItem value="PA">Pennsylvania</MenuItem>
                      <MenuItem value="RI">Rhode Island</MenuItem>
                      <MenuItem value="SC">South Carolina</MenuItem>
                      <MenuItem value="SD">South Dakota</MenuItem>
                      <MenuItem value="TN">Tennessee</MenuItem>
                      <MenuItem value="TX">Texas</MenuItem>
                      <MenuItem value="UT">Utah</MenuItem>
                      <MenuItem value="VT">Vermont</MenuItem>
                      <MenuItem value="VA">Virginia</MenuItem>
                      <MenuItem value="WA">Washington</MenuItem>
                      <MenuItem value="WV">West Virginia</MenuItem>
                      <MenuItem value="WI">Wisconsin</MenuItem>
                      <MenuItem value="WY">Wyoming</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="dialog--input">
                  <TextField
                    id="serviceFacilityAddressZip"
                    label="Zip"
                    type="text"
                    variant="filled"
                    onChange={this.handleChange}
                    defaultValue={singleRow.serviceFacilityAddressZip}
                  />
                </div>
              </div>
            )}
            <h3>Billing Address</h3>
            <label>
              <Checkbox
                id="billingSame"
                label="Same as Service Info"
                checked={this.state.billingSame}
                color="primary"
                onChange={this.handleCheckboxChange}
              />
              <span className="checkbox-label">Same as Service Info</span>
            </label>
            {this.state.billingSame === false && (
              <div>
                <div className="dialog--input">
                  <TextField
                    id="billFacilityName"
                    label="Billing Facility Name"
                    type="text"
                    variant="filled"
                    onChange={this.handleChange}
                    defaultValue={singleRow.billFacilityName}
                  />
                </div>
                <div className="dialog--input">
                  <TextField
                    id="billFacilityAddress"
                    label="Billing Address"
                    type="text"
                    variant="filled"
                    onChange={this.handleChange}
                    defaultValue={singleRow.billFacilityAddress}
                  />
                </div>
                <div className="dialog--input">
                  <TextField
                    id="billFacilityAddressCity"
                    label="City"
                    type="text"
                    variant="filled"
                    onChange={this.handleChange}
                    defaultValue={singleRow.billFacilityAddressCity}
                  />
                </div>
                {singleRow.userRegion === "United Kingdom" ? (
                  <div className="dialog--input">
                    <TextField
                      id="billFacilityAddressZip"
                      label="Postcode"
                      type="text"
                      variant="filled"
                      onChange={this.handleChange}
                      defaultValue={singleRow.billFacilityAddressZip}
                    />
                  </div>
                ) : (
                  <div>
                    <div className="dialog--input">
                      <FormControl variant="filled">
                        <InputLabel id="billFacilityAddressState-label">
                          State
                        </InputLabel>
                        <Select
                          id="billFacilityAddressState"
                          labelId="billFacilityAddressState-label"
                          variant="filled"
                          label="State"
                          value={this.state.billFacilityAddressState}
                          onChange={this.handleSelectChange(
                            "billFacilityAddressState"
                          )}
                        >
                          <MenuItem value="AL">Alabama</MenuItem>
                          <MenuItem value="AK">Alaska</MenuItem>
                          <MenuItem value="AZ">Arizona</MenuItem>
                          <MenuItem value="AR">Arkansas</MenuItem>
                          <MenuItem value="CA">California</MenuItem>
                          <MenuItem value="CO">Colorado</MenuItem>
                          <MenuItem value="CT">Connecticut</MenuItem>
                          <MenuItem value="DE">Delaware</MenuItem>
                          <MenuItem value="DC">District Of Columbia</MenuItem>
                          <MenuItem value="FL">Florida</MenuItem>
                          <MenuItem value="GA">Georgia</MenuItem>
                          <MenuItem value="HI">Hawaii</MenuItem>
                          <MenuItem value="ID">Idaho</MenuItem>
                          <MenuItem value="IL">Illinois</MenuItem>
                          <MenuItem value="IN">Indiana</MenuItem>
                          <MenuItem value="IA">Iowa</MenuItem>
                          <MenuItem value="KS">Kansas</MenuItem>
                          <MenuItem value="KY">Kentucky</MenuItem>
                          <MenuItem value="LA">Louisiana</MenuItem>
                          <MenuItem value="ME">Maine</MenuItem>
                          <MenuItem value="MD">Maryland</MenuItem>
                          <MenuItem value="MA">Massachusetts</MenuItem>
                          <MenuItem value="MI">Michigan</MenuItem>
                          <MenuItem value="MN">Minnesota</MenuItem>
                          <MenuItem value="MS">Mississippi</MenuItem>
                          <MenuItem value="MO">Missouri</MenuItem>
                          <MenuItem value="MT">Montana</MenuItem>
                          <MenuItem value="NE">Nebraska</MenuItem>
                          <MenuItem value="NV">Nevada</MenuItem>
                          <MenuItem value="NH">New Hampshire</MenuItem>
                          <MenuItem value="NJ">New Jersey</MenuItem>
                          <MenuItem value="NM">New Mexico</MenuItem>
                          <MenuItem value="NY">New York</MenuItem>
                          <MenuItem value="NC">North Carolina</MenuItem>
                          <MenuItem value="ND">North Dakota</MenuItem>
                          <MenuItem value="OH">Ohio</MenuItem>
                          <MenuItem value="OK">Oklahoma</MenuItem>
                          <MenuItem value="OR">Oregon</MenuItem>
                          <MenuItem value="PA">Pennsylvania</MenuItem>
                          <MenuItem value="RI">Rhode Island</MenuItem>
                          <MenuItem value="SC">South Carolina</MenuItem>
                          <MenuItem value="SD">South Dakota</MenuItem>
                          <MenuItem value="TN">Tennessee</MenuItem>
                          <MenuItem value="TX">Texas</MenuItem>
                          <MenuItem value="UT">Utah</MenuItem>
                          <MenuItem value="VT">Vermont</MenuItem>
                          <MenuItem value="VA">Virginia</MenuItem>
                          <MenuItem value="WA">Washington</MenuItem>
                          <MenuItem value="WV">West Virginia</MenuItem>
                          <MenuItem value="WI">Wisconsin</MenuItem>
                          <MenuItem value="WY">Wyoming</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div className="dialog--input">
                      <TextField
                        id="billFacilityAddressZip"
                        label="Zip"
                        type="text"
                        variant="filled"
                        onChange={this.handleChange}
                        defaultValue={singleRow.billFacilityAddressZip}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="update--column">
            <h2>Quote Information</h2>
            <h5>Date</h5>
            <p>{reformatTimestamp(singleRow.date.seconds)}</p>
            <h5>Quotes</h5>

            <ul>
              {singleRow.coverageBronze === true && (
                <li key="coverageBronze">
                  {singleRow.coverageBronzeFormUrl ? (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={
                        "https://firebasestorage.googleapis.com/v0/b/prescotts-service-app.appspot.com/o/quotes%2F" +
                        this.substringBetween(
                          singleRow.coverageBronzeFormUrl,
                          "quotes/",
                          "?GoogleAccessId"
                        ) +
                        "?alt=media"
                      }
                    >
                      Bronze
                    </a>
                  ) : (
                    <span>Building Bronze Quote...</span>
                  )}
                </li>
              )}
              {singleRow.coverageSilver === true && (
                <li key="coverageSilver">
                  {singleRow.coverageSilverFormUrl ? (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={
                        "https://firebasestorage.googleapis.com/v0/b/prescotts-service-app.appspot.com/o/quotes%2F" +
                        this.substringBetween(
                          singleRow.coverageSilverFormUrl,
                          "quotes/",
                          "?GoogleAccessId"
                        ) +
                        "?alt=media"
                      }
                    >
                      Silver
                    </a>
                  ) : (
                    <span>Building Silver Quote...</span>
                  )}
                </li>
              )}
              {singleRow.coverageGold === true && (
                <li key="coverageGold">
                  {singleRow.coverageGoldFormUrl ? (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={
                        "https://firebasestorage.googleapis.com/v0/b/prescotts-service-app.appspot.com/o/quotes%2F" +
                        this.substringBetween(
                          singleRow.coverageGoldFormUrl,
                          "quotes/",
                          "?GoogleAccessId"
                        ) +
                        "?alt=media"
                      }
                    >
                      Gold
                    </a>
                  ) : (
                    <span>Building Gold Quote...</span>
                  )}
                </li>
              )}
              {singleRow.coveragePlatinum === true && (
                <li key="coveragePlatinum">
                  {singleRow.coveragePlatinumFormUrl ? (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={
                        "https://firebasestorage.googleapis.com/v0/b/prescotts-service-app.appspot.com/o/quotes%2F" +
                        this.substringBetween(
                          singleRow.coveragePlatinumFormUrl,
                          "quotes/",
                          "?GoogleAccessId"
                        ) +
                        "?alt=media"
                      }
                    >
                      Platinum
                    </a>
                  ) : (
                    <span>Building Platinum Quote...</span>
                  )}
                </li>
              )}
            </ul>
            {singleRow.microscopes && (
              <div>
                <h5>Microscopes</h5>
                <div className="update--images">
                  <ul>
                    {singleRow.microscopes.map((singleScope, i) => {
                      return (
                        <li key={i}>
                          {singleScope.make} - {singleScope.model}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            )}
            <div className="dialog--input">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="filled"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="serviceTermStartDate"
                  label="Contract Start Date"
                  value={this.state.serviceTermStartDate}
                  onChange={this.handleTermStartDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
            <div className="dialog--input">
              <TextField
                id="serviceTerm"
                label="Contract Length (Years)"
                type="number"
                variant="filled"
                defaultValue={singleRow.serviceTerm}
                onChange={this.handleChange}
              />
            </div>
            <div className="dialog--input">
              <TextField
                id="purchaseOrder"
                label="Purchase Order"
                variant="filled"
                type="email"
                onChange={this.handleChange}
                defaultValue={singleRow.purchaseOrder}
              ></TextField>
            </div>
            <div className="dialog--input">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="filled"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="closeDate"
                  label="Expected Close Date"
                  value={this.state.closeDate}
                  onChange={this.handleCloseDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>

            <div className="dialog--input">
              <FormControl variant="filled">
                <InputLabel id="managedBy-label">Contract Type *</InputLabel>
                <Select
                  id="contractType"
                  labelId="contractType-label"
                  variant="filled"
                  label="Contract Type"
                  required
                  value={this.state.contractType}
                  onChange={this.handleSelectChange("contractType")}
                >
                  <MenuItem value="New">New</MenuItem>
                  <MenuItem value="Renewal">Renewal</MenuItem>
                </Select>
              </FormControl>
            </div>

            <TextField
              id="notes_build"
              label="Notes"
              variant="filled"
              multiline={true}
              rows={5}
              rowsMax={5}
              onChange={this.handleChange}
              defaultValue={singleRow.notes_build}
            ></TextField>

            <h3>Questions</h3>
            <div className="dialog--input">
              <TextField
                id="additionalContacts"
                label="Any additional Contacts for this facility?"
                type="text"
                variant="filled"
                multiline={true}
                rows={5}
                rowsMax={5}
                defaultValue={singleRow.additionalContacts}
                onChange={this.handleChange}
              />
            </div>

            <div className="dialog--input">
              <FormControl variant="filled">
                <InputLabel id="managedBy-label">
                  Were all microscopes quoted at the facility? *
                </InputLabel>

                <Select
                  id="quotedFacility"
                  labelId="quotedFacility"
                  variant="filled"
                  label="Were all microscopes quoted at the facility?"
                  required
                  value={this.state.quotedFacility}
                  onChange={this.handleSelectChange("quotedFacility")}
                >
                  <MenuItem value="Yes">Yes</MenuItem>
                  <MenuItem value="No">No</MenuItem>
                </Select>
              </FormControl>
            </div>

            {this.state.quotedFacility === "No" && (
              <div className="dialog--input">
                <TextField
                  id="quotedFacilityWhyNo"
                  label="Why?"
                  type="text"
                  variant="filled"
                  defaultValue={singleRow.quotedFacilityWhyNo}
                  onChange={this.handleChange}
                />
              </div>
            )}

            <div className="dialog--input">
              <FormControl variant="filled">
                <InputLabel id="managedBy-label">
                  Does facility have existing service contract with Prescott’s?
                  *
                </InputLabel>
                <Select
                  id="existingService"
                  labelId="existingService"
                  variant="filled"
                  label="Does facility have existing service contract with Prescott’s?"
                  required
                  value={this.state.existingService}
                  onChange={this.handleSelectChange("existingService")}
                >
                  <MenuItem value="Yes">Yes</MenuItem>
                  <MenuItem value="No">No</MenuItem>
                </Select>
              </FormControl>
            </div>

            <div className="dialog--input">
              <FormControl variant="filled">
                <InputLabel id="managedBy-label">
                  Does facility have an Pentero or Kinevo scopes? *
                </InputLabel>
                <Select
                  id="penteroKinevo"
                  labelId="penteroKinevo"
                  variant="filled"
                  label="Does facility have an Pentero or Kinevo scopes?"
                  required
                  value={this.state.penteroKinevo}
                  onChange={this.handleSelectChange("penteroKinevo")}
                >
                  <MenuItem value="Yes">Yes</MenuItem>
                  <MenuItem value="No">No</MenuItem>
                </Select>
              </FormControl>
            </div>

            {this.state.penteroKinevo === "Yes" && (
              <div className="dialog--input">
                <FormControl variant="filled">
                  <InputLabel id="managedBy-label">
                    Have these been quoted? *
                  </InputLabel>
                  <Select
                    id="penteroKinevoQuoted"
                    labelId="penteroKinevoQuoted"
                    variant="filled"
                    label="Have these been quoted?"
                    value={this.state.penteroKinevoQuoted}
                    onChange={this.handleSelectChange("penteroKinevoQuoted")}
                  >
                    <MenuItem value="Yes">Yes</MenuItem>
                    <MenuItem value="No">No</MenuItem>
                  </Select>
                </FormControl>
              </div>
            )}
          </div>
          <div className="update--column">
            <h2>Internal Information</h2>
            {profile.permission === "Admin" && (
              <div>
                <h5>Ordered By</h5>
                <p>{singleRow.userName}</p>
                {singleRow.userRegion && (
                  <div>
                    <h5>Region</h5>
                    <p>{singleRow.userRegion}</p>
                  </div>
                )}
              </div>
            )}
            <div>
              <h5>Quote Value</h5>
              <ul>
                {quoteList.map((row, i) => {
                  return (
                    <li key={i}>
                      {row.value} ({row.level})
                    </li>
                  );
                })}
              </ul>
            </div>
            {profile.permission === "Admin" && (
              <div className="dialog--input">
                <TextField
                  id="discountPercent"
                  label="Optional Discount (%)"
                  type="number"
                  variant="filled"
                  defaultValue={singleRow.discountPercent}
                  onChange={this.handleChange}
                />
              </div>
            )}

            {profile.permission === "Admin" && singleRow.status !== "Open" && (
              <div className="dialog--input">
                <TextField
                  id="valueOverride"
                  label="Total Contract Value Override"
                  type="number"
                  variant="filled"
                  defaultValue={singleRow.valueOverride}
                  onChange={this.handleChange}
                />
              </div>
            )}

            <div className="dialog--input">
              <FormControl variant="filled">
                <InputLabel id="customerType-label">Customer Type</InputLabel>
                <Select
                  id="customerType"
                  labelId="customerType-label"
                  variant="filled"
                  label="Customer Type"
                  value={this.state.customerType}
                  onChange={this.handleSelectChange("customerType")}
                >
                  <MenuItem value="Hospital">Hospital</MenuItem>
                  <MenuItem value="Surgery Center">Surgery Center</MenuItem>
                  <MenuItem value="Clinic">Clinic</MenuItem>
                  <MenuItem value="Other">Other</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="dialog--input">
              <FormControl variant="filled">
                <InputLabel id="customerStatus-label">
                  Customer Status
                </InputLabel>
                <Select
                  id="customerStatus"
                  labelId="customerStatus-label"
                  variant="filled"
                  label="Customer Status"
                  value={this.state.customerStatus}
                  onChange={this.handleSelectChange("customerStatus")}
                >
                  <MenuItem value="New">New</MenuItem>
                  <MenuItem value="Existing">Existing</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="dialog--input">
              <FormControl variant="filled">
                <InputLabel id="managedBy-label">Managed By</InputLabel>
                <Select
                  id="managedBy"
                  labelId="managedBy-label"
                  variant="filled"
                  label="Managed By"
                  value={this.state.managedBy}
                  onChange={this.handleSelectChange("managedBy")}
                >
                  <MenuItem value="New - Rep">New - Rep</MenuItem>
                  <MenuItem value="New - Dept">New - Dept</MenuItem>
                  <MenuItem value="Renewal - Rep">Renewal - Rep</MenuItem>
                  <MenuItem value="Renewal - Dept">Renewal - Dept</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="dialog--input">
              <FormControl variant="filled">
                <InputLabel id="status-label">Status</InputLabel>
                <Select
                  id="status"
                  labelId="status-label"
                  variant="filled"
                  label="Status"
                  value={this.state.status}
                  onChange={this.handleSelectChange("status")}
                >
                  <MenuItem value="Open">Open</MenuItem>
                  <MenuItem value="Closed - Won">Closed - Won</MenuItem>
                  <MenuItem value="Closed - Lost">Closed - Lost</MenuItem>
                  <MenuItem value="Closed - Other Option Selected">
                    Closed - Other Option Selected
                  </MenuItem>
                </Select>
              </FormControl>
            </div>

            {this.state.status === "Closed - Won" && (
              <div className="dialog--input">
                <FormControl variant="filled">
                  <InputLabel id="status-label">Coverage level</InputLabel>

                  <Select
                    id="status"
                    labelId="status-label"
                    variant="filled"
                    label="Coverage level"
                    value={this.state.closed_status}
                    onChange={this.handleSelectChange("closed_status")}
                  >
                    <MenuItem value="Silver">Silver</MenuItem>
                    <MenuItem value="Gold">Gold</MenuItem>
                    <MenuItem value="Platinum">Platinum</MenuItem>
                  </Select>
                </FormControl>
              </div>
            )}

            <TextField
              id="notes"
              label="Notes"
              variant="filled"
              multiline={true}
              rows={5}
              rowsMax={5}
              onChange={this.handleChange}
              defaultValue={singleRow.notes}
            ></TextField>
          </div>
        </div>
      </Dialog>
    );
  }
}

const mapStateToProps = state => {
  return {
    profile: state.firebase.profile
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateQuote: data => dispatch(updateQuote(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(QuoteDialog);
