import React from "react";
import { NavLink } from "react-router-dom";

// Images
import CashIcon from "../../../img/icon-cash.svg";

const pricingSelect = () => {
  return (
    <div className="nav-blocks">
      <NavLink to="/service/pricing/manage-us">
        <div className="nav-blocks--block">
          <span className="icon">
            <img src={CashIcon} alt="U.S. Pricing" />
          </span>
          <span className="label">U.S. Pricing</span>
        </div>
      </NavLink>
      <NavLink to="/service/pricing/manage-uk">
        <div className="nav-blocks--block">
          <span className="icon">
            <img src={CashIcon} alt="U.K. Pricing" />
          </span>
          <span className="label">U.K. Pricing</span>
        </div>
      </NavLink>
    </div>
  );
};

export default pricingSelect;
