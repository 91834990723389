import React from "react";
import { NavLink } from "react-router-dom";

// Images
import QuoteIcon from "../../img/icon-write.svg";
import InventoryIcon from "../../img/icon-box-closed.svg";
import SRAIcon from "../../img/icon-tools.svg";
import PartsIcon from "../../img/icon-list.svg";
import PasswordIcon from "../../img/icon-lock-open.svg";
import VideoIcon from "../../img/icon-video.svg";

const ServiceRep = (showPass, showVideo) => {
  return (
    <div className="nav-blocks">
      <NavLink to="/service/quote/request">
        <div className="nav-blocks--block">
          <span className="icon">
            <img src={QuoteIcon} alt="Service Contract Quote" />
          </span>
          <span className="label">
            Contract
            <br />
            Quote
          </span>
        </div>
      </NavLink>
      <NavLink to="/service/inventory/request">
        <div className="nav-blocks--block">
          <span className="icon">
            <img src={InventoryIcon} alt="Inventory Requests" />
          </span>
          <span className="label">
            Inventory
            <br />
            Requests
          </span>
        </div>
      </NavLink>
      <NavLink to="/service/sra/request">
        <div className="nav-blocks--block">
          <span className="icon">
            <img src={SRAIcon} alt="SRA Requests" />
          </span>
          <span className="label">
            SRA
            <br />
            Requests
          </span>
        </div>
      </NavLink>
      <NavLink to="/service/parts/view">
        <div className="nav-blocks--block">
          <span className="icon">
            <img src={PartsIcon} alt="Parts Price List" />
          </span>
          <span className="label">
            Parts
            <br />
            Price List
          </span>
        </div>
      </NavLink>
      {showPass.showPass === true && (
        <NavLink to="/service/password">
          <div className="nav-blocks--block">
            <span className="icon">
              <img src={PasswordIcon} alt="Password Generator" />
            </span>
            <span className="label">
              Password
              <br />
              Generator
            </span>
          </div>
        </NavLink>
      )}
      {showPass.showVideo === true && (
        <NavLink to="/service/video/view">
          <div className="nav-blocks--block">
            <span className="icon">
              <img src={VideoIcon} alt="Videos" />
            </span>
            <span className="label">
              Training
              <br />
              Videos
            </span>
          </div>
        </NavLink>
      )}
    </div>
  );
};

export default ServiceRep;
