import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect, isLoaded, isEmpty } from "react-redux-firebase";
import _ from "lodash";
import { invertDirection } from "../../../../util/functions";

// Components
import LoadingBlock from "../../../global/LoadingBlock";
import ServiceSraTableRow from "./ServiceSraTableRow";

// MUI Stuff
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Button from "@material-ui/core/Button";

class ServiceSraTable extends Component {
  state = {
    columnToSort: "date",
    sortDirection: "desc"
  };
  handleShowMore = () => {
    this.props.showMore(this.props.perPage);
  };
  handleSort = columnName => {
    this.setState(state => ({
      columnToSort: columnName,
      sortDirection:
        state.columnToSort === columnName
          ? invertDirection(state.sortDirection)
          : "asc"
    }));
  };
  render() {
    const { sras, perPage, firestoreQuery, permission } = this.props;
    const orderedSras = _.orderBy(
      sras,
      [this.state.columnToSort],
      [this.state.sortDirection]
    );
    return (
      <div>
        {!isLoaded(sras) ? (
          <LoadingBlock />
        ) : isEmpty(sras) ? (
          <h2>No SRA Requests Found</h2>
        ) : (
          <div>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <TableSortLabel
                        direction={this.state.sortDirection}
                        onClick={() => this.handleSort("sra")}
                      >
                        SRA #
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        direction={this.state.sortDirection}
                        onClick={() => this.handleSort("date")}
                      >
                        Date
                      </TableSortLabel>
                    </TableCell>
                    {permission !== "Service Rep" && (
                      <TableCell>
                        <TableSortLabel
                          direction={this.state.sortDirection}
                          onClick={() => this.handleSort("userName")}
                        >
                          Ordered By
                        </TableSortLabel>
                      </TableCell>
                    )}
                    {permission === "Admin" && (
                      <TableCell>
                        <TableSortLabel
                          direction={this.state.sortDirection}
                          onClick={() => this.handleSort("userRegion")}
                        >
                          Region
                        </TableSortLabel>
                      </TableCell>
                    )}
                    <TableCell>
                      <TableSortLabel
                        direction={this.state.sortDirection}
                        onClick={() => this.handleSort("status")}
                      >
                        Status
                      </TableSortLabel>
                    </TableCell>
                    {permission !== "Service Rep" && (
                      <TableCell>Actions</TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orderedSras.map((sra, index) => (
                    <ServiceSraTableRow
                      singleRow={sra}
                      key={sra.id}
                      rowId={sra.id}
                      collection="sras"
                      permission={permission}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {sras.length % 50 === 0 && (
              <Button
                className="show-more"
                variant="contained"
                color="primary"
                onClick={this.handleShowMore}
              >
                Show Next 50 Requests
              </Button>
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    sras: state.firestore.ordered.sras
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect(props => {
    return [
      {
        collection: "sras",
        where: props.firestoreQuery,
        limit: props.perPage,
        orderBy: ["date", "desc"]
      }
    ];
  })
)(ServiceSraTable);
