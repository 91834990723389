const initState = {};

const salesFormsReducer = (state = initState, action) => {
  switch (action.type) {
    case "CREATE_SALES_FORM":
      console.log("Form Added", action.salesforms);
      return state;
    default:
      return state;
  }
};

export default salesFormsReducer;
