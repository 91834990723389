import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect, isLoaded, isEmpty } from "react-redux-firebase";
import _ from "lodash";
import { invertDirection } from "../../../../util/functions";

// Components
import LoadingBlock from "../../../global/LoadingBlock";
import ServiceVideoTableRow from "./ServiceVideoTableRow";

// MUI Stuff
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Button from "@material-ui/core/Button";

class ServiceVideoTable extends Component {
  handleShowMore = () => {
    this.props.showMore(this.props.perPage);
  };
  render() {
    const { videos, perPage, firestoreQuery, permission } = this.props;
    return (
      <div>
        {!isLoaded(videos) ? (
          <LoadingBlock />
        ) : isEmpty(videos) ? (
          <h2>No Videos Found</h2>
        ) : (
          <div>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Video</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Order</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {videos.map((video, index) => (
                    <ServiceVideoTableRow
                      singleRow={video}
                      key={video.id}
                      rowId={video.id}
                      collection="videos"
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {videos.length % 50 === 0 && (
              <Button
                className="show-more"
                variant="contained"
                color="primary"
                onClick={this.handleShowMore}
              >
                Show Next 50 Requests
              </Button>
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    videos: state.firestore.ordered.videos
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect(props => {
    return [
      {
        collection: "videos",
        where: props.firestoreQuery,
        limit: props.perPage,
        orderBy: ["order", "asc"]
      }
    ];
  })
)(ServiceVideoTable);
