import React from "react";
import { NavLink } from "react-router-dom";

// Images
import VideoIcon from "../../../../img/icon-video.svg";
import UploadIcon from "../../../../img/icon-upload.svg";
import ArchiveIcon from "../../../../img/icon-box-open.svg";

const ServiceVideoTabs = () => {
  return (
    <div>
      <div className="tabs">
        <NavLink to="/service/video/view" activeClassName="active">
          <img src={VideoIcon} alt="View" />
          View
        </NavLink>
        <NavLink to="/service/video/create" activeClassName="active">
          <img src={UploadIcon} alt="Upload" />
          Upload
        </NavLink>
        <NavLink to="/service/video/manage" activeClassName="active">
          <img src={ArchiveIcon} alt="Manage" />
          Manage
        </NavLink>
      </div>
    </div>
  );
};

export default ServiceVideoTabs;
