import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

// Material UI
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

// Components
import ServiceTitleBar from "../../../components/nav/ServiceTitleBar";

class servicePassword extends Component {
  state = {
    serial: "",
    response: "",
    responseStatus: ""
  };
  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value,
      response: "",
      responseStatus: ""
    });
  };
  handleSubmit = e => {
    e.preventDefault();
    let serialEntered = this.state.serial;
    let serialNoDash = serialEntered.replace(/-/g, "");
    let serialClean = serialNoDash.replace(/ /g, "");
    // Error handling: Verify only numbers were entered
    if (!serialClean.match(/^\d+$/)) {
      // Return error if other than numbers entered
      this.setState({
        responseStatus: "error",
        response:
          "The serial number can only contain numbers. Please try again."
      });
    } else {
      // Error handling: Verify exactly 10 digits were entered
      if (serialClean.length !== 10) {
        // Return error if number entered isn't exactly 10 digits
        this.setState({
          responseStatus: "error",
          response: "The number entered should be 10 digits. Please try again."
        });
      } else {
        //get last 6 digits, split into 2 digit array
        let model = serialClean.slice(-6);
        let modelVals = model.match(/.{2}/g);
        let pass = "";
        //use multipliers on last 6 digits in groups of 2
        let doubleZeros = [312, 1155, 2016];
        let multipliers = [8, 15, 42];
        modelVals.forEach(function(val, index) {
          if (val === "00") {
            pass += parseInt(doubleZeros[index]);
          } else {
            let temp = (
              parseInt(modelVals[index]) * multipliers[index]
            ).toString();
            pass += temp;
          }
        });
        //determine remainder, add number to end of pass in increasing multipliers
        let undetermined = 15 - pass.length;
        pass += undetermined;
        for (let i = 2; i < 10; i++) {
          if (pass.length < 15) {
            pass += (undetermined * i).toString();
          }
        }
        //trim to 16, split and add '-'
        pass = pass.substring(0, 16);
        let s1 = pass.slice(0, 5);
        let s2 = pass.slice(5, 10);
        let s3 = pass.slice(10, 15);
        let password = s1 + "-" + s2 + "-" + s3;
        this.setState({
          responseStatus: "password",
          response: password
        });
      }
    }
  };
  render() {
    const { auth } = this.props;
    if (!auth.uid) return <Redirect to="/login" />;
    return (
      <div>
        <ServiceTitleBar />
        <h1>Password Generator</h1>
        <form onSubmit={this.handleSubmit}>
          <div className="field">
            <TextField
              id="serial"
              label="Serial Number"
              type="text"
              variant="filled"
              required
              onChange={this.handleChange}
            />
          </div>
          <div className="field">
            <Button type="submit" variant="contained" color="primary">
              Generate Password
            </Button>
          </div>
        </form>
        {this.state.responseStatus === "error" && (
          <div className="password-error">
            <h2>Input Error</h2>
            <p>{this.state.response}</p>
          </div>
        )}
        {this.state.responseStatus === "password" && (
          <div className="password-response">
            <p>Password</p>
            <h2>{this.state.response}</h2>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth
  };
};

export default connect(mapStateToProps)(servicePassword);
