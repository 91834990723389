const initState = {};

const videoReducer = (state = initState, action) => {
  switch (action.type) {
    case "CREATE_VIDEO":
      return state;
    case "CREATE_VIDEO_ERROR":
      console.log("Error Creating Video");
      return state;
    case "UPDATE_VIDEO":
      return state;
    case "UPDATE_VIDEO_ERROR":
      console.log("Error Updating Video");
      return state;
    default:
      return state;
  }
};

export default videoReducer;
