import React from "react";
import { connect } from "react-redux";

// Actions
import { removeDocument } from "../../store/actions/globalActions";

// MUI Stuff
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const RemoveDialog = ({
  collection,
  docId,
  name,
  open,
  handleClose,
  removeDocument
}) => {
  const handleRemove = () => {
    // console.log(docId);
    removeDocument(docId, collection);
    handleClose();
  };
  return (
    <Dialog open={open} onClose={handleClose} className="dialog--remove">
      <DialogTitle id="alert-dialog-title">
        Are you sure you want to remove {name}?
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          This action cannot be undone.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Keep</Button>
        <Button onClick={handleRemove} color="primary" autoFocus>
          Remove
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    removeDocument: (docId, collection) =>
      dispatch(removeDocument(docId, collection))
  };
};

export default connect(
  null,
  mapDispatchToProps
)(RemoveDialog);
