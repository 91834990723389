const initState = {
  pricingUpdateUK: false,
  pricingErrorUK: false
};

const pricingReducer = (state = initState, action) => {
  switch (action.type) {
    case "UK_PRICING_SAVED":
      console.log("UK_PRICING_SAVED");
      return { ...state, pricingUpdateUK: !state.pricingUpdateUK };
    case "UK_PRICING_ERROR":
      return { ...state, pricingErrorUK: true };
    default:
      return state;
  }
};

export default pricingReducer;
