import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect, isLoaded, isEmpty } from "react-redux-firebase";
import _ from "lodash";
import { invertDirection } from "../../../../util/functions";

// Components
import LoadingBlock from "../../../global/LoadingBlock";
import ServiceVideoTableRow from "./ServiceVideoTableRow";

// MUI Stuff
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Button from "@material-ui/core/Button";

class ServiceVideoList extends Component {
  handleShowMore = () => {
    this.props.showMore(this.props.perPage);
  };
  render() {
    const { videos, perPage, firestoreQuery, permission } = this.props;
    return (
      <div>
        {!isLoaded(videos) ? (
          <LoadingBlock />
        ) : isEmpty(videos) ? (
          <h2>No Videos Found</h2>
        ) : (
          <div className="video--grid">
            {videos.map((video, index) => (
              <div className="video--column">
                <h3>{video.title}</h3>
                <div className="video">
                  <video controls>
                    <source src={video.video[0]} type="video/mp4" />
                  </video>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    videos: state.firestore.ordered.videos
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect(props => {
    return [
      {
        collection: "videos",
        where: props.firestoreQuery,
        limit: props.perPage,
        orderBy: ["order", "asc"]
      }
    ];
  })
)(ServiceVideoList);
