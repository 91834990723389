import "date-fns";
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";

// Actions
import { savePricing } from "../../../store/actions/pricingActions";

// Components
import CSVReader from "react-csv-reader";

// Material UI
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

// Images
import AddIcon from "../../../img/icon-circle-plus.svg";
import RemoveIcon from "../../../img/icon-circle-x.svg";

class pricingManage extends Component {
  state = {};

  componentDidUpdate(prevProps) {
    if (this.props.pricingus !== prevProps.pricingus) {
      let pricing = this.props.pricingus;

      this.setState({
        pricing: pricing,
        saving: false,
        csvHelper: "",
        csvError: false
      });
    }
  }

  handleChange = (i, e) => {
    let pricing = this.state.pricing;
    let key = e.target.id;
    let value = e.target.value;

    pricing[i][key] = value;

    this.setState({
      pricing: pricing
    });
  };

  addPricingRow = i => {
    let pricing = this.state.pricing;
    const emptyRow = {
      make: "",
      model: "",
      silver: "",
      gold: "",
      platinum: ""
    };

    pricing.splice(i + 1, 0, emptyRow);

    this.setState({
      pricing: pricing
    });
  };

  removePricingRow = i => {
    let pricing = this.state.pricing;

    if (i > -1) {
      pricing.splice(i, 1);
    }

    this.setState({
      pricing: pricing
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.setState({ saving: true });
    this.props.savePricing(this.state.pricing, "us");
  };

  handleCsvLoaded = data => {
    // Verify CSV has data
    if (data.length === 0) {
      this.setState({
        csvHelper: "The uploaded file is empty",
        csvError: true
      });
      return;
    }

    // Verify CSV data matches the format
    const columns = ["make", "model", "silver", "gold", "platinum"];
    let rowTest = data[0];
    columns.forEach(key => {
      if (!rowTest.hasOwnProperty(key)) {
        this.setState({
          csvHelper:
            "This CSV file is missing a " +
            key +
            " column. Please note: column names need to be exact and are case-sensitive.",
          csvError: true
        });
        return;
      }
    });

    // Sort array by make/model
    data.sort((a, b) => {
      if (a.make === b.make) {
        return a.model < b.model ? -1 : 1;
      } else {
        return a.make < b.make ? -1 : 1;
      }
    });

    this.setState({
      pricing: data
    });
  };

  handleCsvError = () => {
    this.setState({
      csvHelper: "There was an error loading the CSV file",
      csvError: true
    });
  };

  handleDownload = () => {
    const currentPricing = this.props.pricingus;

    console.log("download file");

    let formatted = [];

    const headerRow = ["make", "model", "silver", "gold", "platinum"];

    formatted.push(headerRow);

    currentPricing.forEach(row => {
      let priceRow = [row.make, row.model, row.silver, row.gold, row.platinum];
      formatted.push(priceRow);
    });

    let csvContent =
      "data:text/csv;charset=utf-8," +
      formatted.map(e => e.join(",")).join("\n");

    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "current_prescotts_pricing.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  render() {
    const { auth } = this.props;

    // Redirect if logged out
    if (!auth.uid) return <Redirect to="/login" />;

    const parseOptions = {
      header: true,
      dynamicTyping: true,
      skipEmptyLines: true
    };

    const getCsvHelperClass = () => {
      if (this.state.csvError) {
        return "pricing--upload--helper error";
      }
      return "pricing--upload--helper";
    };

    return (
      <div>
        <h1>Manage U.S. Pricing</h1>
        <div className="pricing">
          <div className="pricing--upload">
            <p>
              Upload a CSV to replace all pricing or download a CSV file with
              current pricing,
              <span>
                (CSV files should have 5 columns: make, model, silver, gold,
                platinum)
              </span>
            </p>
            <div className="pricing--buttons">
              <div className="pricing--buttons--col">
                <h3>Upload CSV</h3>
                <CSVReader
                  cssClass="csv-reader-input"
                  label="Upload CSV"
                  onFileLoaded={this.handleCsvLoaded}
                  onError={this.handleCsvError}
                  parserOptions={parseOptions}
                  inputId="pricingCsv"
                  inputName="pricingCsv"
                />
              </div>
              <div className="pricing--buttons--col">
                <h3>Download CSV</h3>
                <Button
                  className="pricing--download"
                  variant="contained"
                  color="primary"
                  onClick={this.handleDownload}
                >
                  Download Current Pricing
                </Button>
              </div>
            </div>
            {this.state.csvHelper !== "" && (
              <div className={getCsvHelperClass()}>{this.state.csvHelper}</div>
            )}
          </div>
          <form onSubmit={this.handleSubmit}>
            {this.state.pricing && !this.state.saving && (
              <table cellPadding="0" cellSpacing="0">
                <thead>
                  <tr>
                    <th>Make</th>
                    <th>Model</th>
                    <th>Silver</th>
                    <th>Gold</th>
                    <th>Platinum</th>
                    <th>Add/Remove</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.pricing.map((row, i) => {
                    return (
                      <tr key={i}>
                        <td className="make">
                          <TextField
                            id="make"
                            label="Make"
                            type="text"
                            variant="filled"
                            value={row.make}
                            required
                            onChange={e => this.handleChange(i, e)}
                          />
                        </td>
                        <td className="model">
                          <TextField
                            id="model"
                            label="Model"
                            type="text"
                            variant="filled"
                            value={row.model}
                            required
                            onChange={e => this.handleChange(i, e)}
                          />
                        </td>
                        <td className="price">
                          <TextField
                            id="silver"
                            label="Silver"
                            type="number"
                            variant="filled"
                            value={row.silver}
                            required
                            onChange={e => this.handleChange(i, e)}
                          />
                        </td>
                        <td className="price">
                          <TextField
                            id="gold"
                            label="Gold"
                            type="number"
                            variant="filled"
                            value={row.gold}
                            required
                            onChange={e => this.handleChange(i, e)}
                          />
                        </td>
                        <td className="price">
                          <TextField
                            id="platinum"
                            label="Platinum"
                            type="number"
                            variant="filled"
                            value={row.platinum}
                            required
                            onChange={e => this.handleChange(i, e)}
                          />
                        </td>
                        <td className="actions">
                          <Button
                            className="microscope-add"
                            variant="contained"
                            color="primary"
                            onClick={() => this.addPricingRow(i)}
                          >
                            <img src={AddIcon} alt="icon" />
                          </Button>
                          {i > 0 && (
                            <Button
                              className="microscope-remove"
                              variant="contained"
                              color="primary"
                              onClick={() => this.removePricingRow(i)}
                            >
                              <img src={RemoveIcon} alt="icon" />
                            </Button>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
            {this.state.saving && <div className="pricing--loading"></div>}
            <div className="pricing--save">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={this.state.saving}
              >
                {this.state.saving ? "Saving..." : "Save Pricing"}
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    pricingus: state.firestore.ordered.pricingus,
    auth: state.firebase.auth
  };
};

const mapDispatchToProps = dispatch => {
  return {
    savePricing: (data, region) => dispatch(savePricing(data, region))
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect([
    {
      collection: "pricingus",
      orderBy: [
        ["make", "asc"],
        ["model", "asc"]
      ]
    }
  ])
)(pricingManage);
