// Requirements
import * as firebase from "firebase/app";
import { getFirebase } from "react-redux-firebase";
const axios = require("axios");

const secondaryConfig = {
  apiKey: "AIzaSyBSPd2utRI5wfQ7nrdFGz_qemO6wU8ajXI",
  authDomain: "prescotts-service-app.firebaseapp.com",
  projectId: "prescotts-service-app",
};

export const createUser = newUser => {
  return (dispatch, getState, getFirebase) => {
    const firebase = getFirebase();
    const firestore = getFirebase().firestore();

    // Workaround to prevent Firebase from logging in
    // new users and logging out admins who are already authed.
    // Create a second app instance and use that to create
    // the account, then log out.
    const secondaryApp = firebase.initializeApp(secondaryConfig, "Secondary");

    secondaryApp
      .auth()
      .createUserWithEmailAndPassword(newUser.email, newUser.password)
      .then(response => {
        return firestore
          .collection("users")
          .doc(response.user.uid)
          .set({
            email: newUser.email,
            name: newUser.name,
            permission: newUser.permission,
            address: newUser.address,
            city: newUser.city,
            state: newUser.state,
            zip: newUser.zip,
            region: newUser.region,
            passwordaccess: newUser.passwordaccess,
            videoaccess: newUser.videoaccess,
            pricingaccess: newUser.pricingaccess,
            active: true,
            deleted: false,
          });
      })
      .then(() => {
        return secondaryApp.auth().signOut();
      })
      .then(() => {
        dispatch({ type: "CREATE_USER_SUCCESS" });
      })
      .catch(err => {
        dispatch({ type: "CREATE_USER_ERROR", err });
      });
  };
};

export const updateUser = data => {
  return (dispatch, getState, getFirebase) => {
    const firestore = getFirebase().firestore();

    let videoaccess = false;
    if (data.videoaccess) {
      videoaccess = data.videoaccess;
    }
    let pricingaccess = false;
    if (data.pricingaccess) {
      pricingaccess = data.pricingaccess;
    }
    let passwordaccess = false;
    if (data.passwordaccess) {
      passwordaccess = data.passwordaccess;
    }

    firestore
      .collection("users")
      .doc(data.id)
      .update({
        name: data.name,
        permission: data.permission,
        address: data.address,
        city: data.city,
        state: data.state,
        zip: data.zip,
        region: data.region,
        passwordaccess: passwordaccess,
        videoaccess: videoaccess,
        pricingaccess: pricingaccess,
      })
      .then(() => {
        dispatch({
          type: "UPDATE_USER_SUCCESS"
        });
      })
      .catch(err => {
        dispatch({ type: "UPDATE_USER_ERROR", err });
      });
  };
};

// TODO: Handle dispatches for these responses

export const disableUser = userId => {
  axios
    .post("https://us-central1-prescotts-service-app.cloudfunctions.net/disableUser", {
      uid: userId
    })
    .then(res => {
      console.log(res);
    })
    .catch(err => {
      console.log(err);
    });
};

export const enableUser = userId => {
  axios
    .post("https://us-central1-prescotts-service-app.cloudfunctions.net/enableUser", {
      uid: userId
    })
    .then(res => {
      console.log(res);
    })
    .catch(err => {
      console.log(err);
    });
};

export const deleteUser = userId => {
  axios
    .post("https://us-central1-prescotts-service-app.cloudfunctions.net/deleteUser", {
      uid: userId
    })
    .then(res => {
      console.log(res);
    })
    .catch(err => {
      console.log(err);
    });
};
