import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { firestoreConnect, isLoaded, isEmpty } from "react-redux-firebase";
import { compose } from "redux";

// Components
import LoadingBlock from "../../global/LoadingBlock";
import FileListTable from "../../global/FileListTable";

const servicePartsView = ({ auth, profile, partpricing }) => {
  // If user isn't logged in
  if (isEmpty(auth)) {
    return <Redirect to="/login" />;
  }
  // If everything went to plan
  if (isLoaded(profile) && isLoaded(partpricing)) {
    return (
      <div>
        <h1>Parts Price List</h1>
        <FileListTable
          rowTitle="Manufacturer"
          rows={partpricing}
          permission={profile.permission}
        />
      </div>
    );
  }
  // If part pricing isn't loaded
  return <LoadingBlock />;
};

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    partpricing: state.firestore.ordered.partpricing
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect([
    {
      collection: "partpricing",
      orderBy: ["name", "asc"]
    }
  ])
)(servicePartsView);
