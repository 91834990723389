import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

// Components
import LoadingBlock from "../components/LoadingBlock";

// Images
import UsersIcon from "../img/icon-users.svg";
// import SalesIcon from "../img/icon-write.svg";
import ServiceIcon from "../img/icon-tools.svg";

class home extends Component {
  render() {
    const { auth, profile } = this.props;
    if (!auth.uid) return <Redirect to="/login" />;
    // Remove in future, once sales portion is live
    if (auth.uid) return <Redirect to="/service" />;
    // End remove
    return (
      <div>
        {profile.permission ? (
          <div className="nav-blocks home">
            {profile.permission === "Admin" && (
              <NavLink to="/users/manage">
                <div className="nav-blocks--block">
                  <span className="icon">
                    <img src={UsersIcon} alt="Users" />
                  </span>
                  <span className="label">Users</span>
                </div>
              </NavLink>
            )}
            {/*
              <NavLink to="/sales">
                <div className="nav-blocks--block">
                  <span className="icon">
                    <img src={SalesIcon} alt="Sales" />
                  </span>
                  <span className="label">Sales</span>
                </div>
              </NavLink>
            */}
            <NavLink to="/service">
              <div className="nav-blocks--block">
                <span className="icon">
                  <img src={ServiceIcon} alt="Service" />
                </span>
                <span className="label">Service</span>
              </div>
            </NavLink>
          </div>
        ) : (
          <LoadingBlock />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile
  };
};

export default connect(mapStateToProps)(home);
