export const createInventory = (data, collection) => {
  return (dispatch, getState, getFirebase) => {
    const firestore = getFirebase().firestore();
    const profile = getState().firebase.profile;
    const userId = getState().firebase.auth.uid;
    firestore
      .collection(collection)
      .add({
        ...data,
        user: userId,
        userName: profile.name,
        userRegion: profile.region,
        userAddress: profile.address,
        userCity: profile.city,
        userState: profile.state,
        userZip: profile.zip,
        userEmail: profile.email,
        status: "Requested",
        date: new Date()
      })
      .then(() => {
        dispatch({
          type: "CREATE_INVENTORY",
          inventory: data
        });
      })
      .catch(err => {
        dispatch({ type: "CREATE_INVENTORY_ERROR", err });
      });
  };
};

export const updateInventory = (data, collection) => {
  return (dispatch, getState, getFirebase) => {
    const firestore = getFirebase().firestore();
    let toUpdate;
    if (data.info) {
      toUpdate = { status: data.status, info: data.info };
    } else {
      toUpdate = { status: data.status };
    }
    firestore
      .collection(collection)
      .doc(data.id)
      .update(toUpdate)
      .then(() => {
        dispatch({
          type: "UPDATE_INVENTORY"
        });
      })
      .catch(err => {
        dispatch({ type: "UPDATE_INVENTORY_ERROR", err });
      });
  };
};
