import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect, isLoaded, isEmpty } from "react-redux-firebase";
import _ from "lodash";
import { invertDirection } from "../../../../util/functions";

// Components
import LoadingBlock from "../../../global/LoadingBlock";
import ServiceQuoteTableRow from "./ServiceQuoteTableRow";


// MUI Stuff
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";


class QuoteTableAdmin extends Component {
  state = {
    columnToSort: "date",
    sortDirection: "desc",
    searched_quotes : [],
    search_string : '',

  };

  componentWillUnmount() { 
    console.log("UNMOUNT", this.props);
  }

  handleSearch = () => {

    let search = [];
    this.props.full_quotes.forEach(element => {
      if(element.serviceFacilityName.toLowerCase().includes(this.state.search_string.toLowerCase()) && !element.deleted){
        search.push(element);
      }
    });

    if(search.length == 0){
      alert('No Quotes Found')
    }

    this.setState({ searched_quotes: search });

    


  };

  handleSearchRep = () => {

    let search = [];
    this.props.full_quotes.forEach(element => {
      if(element.userName){
        if(element.userName.toLowerCase().includes(this.state.search_string.toLowerCase()) && !element.deleted){
          search.push(element);
        }
      }
      
    });

    if(search.length == 0){
      alert('No Quotes Found')
    }

    this.setState({ searched_quotes: search });

    


  };


  handleResetSearch = () => {
    this.setState({ search_string: '' });
    this.setState({ searched_quotes: [] });
  };


  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };


  
  
  handleSort = columnName => {
    this.setState(state => ({
      columnToSort: columnName,
      sortDirection:
        state.columnToSort === columnName
          ? invertDirection(state.sortDirection)
          : "asc"
    }));
  };

  render() {
    const { full_quotes, perPage, permission } = this.props;

    return (
      <div>
         {!isLoaded(full_quotes) ? (
          <LoadingBlock />
        ) : this.state.searched_quotes.length == 0 ? (
         
              <div>
              
              <div className="dialog--input" >
              <TextField
                style={ { width: '600px' } }
                id="search_string"
                label="Search by Facility Name"
                variant="filled"
                onChange={this.handleChange}
                defaultValue={this.state.search_string}
              ></TextField>
              </div>

              <Button
                style={ { marginBottom: '50px' } }
                variant="contained"
                color="primary"
                onClick={this.handleSearch}
              >
                Search
              </Button>


              <div className="dialog--input" >
              <TextField
                style={ { width: '600px' } }
                id="search_string"
                label="Search by Rep Name"
                variant="filled"
                onChange={this.handleChange}
                defaultValue={this.state.search_string}
              ></TextField>
              </div>

              <Button
                variant="contained"
                color="primary"
                onClick={this.handleSearchRep}
              >
                Search
              </Button>


              

              
              </div>


        ) : (
          <div>
              <Button
                style={ { marginBottom: '15px' } }
                variant="contained"
                color="primary"
                onClick={this.handleResetSearch}
              >
                Reset Search
              </Button>


            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <TableSortLabel
                        direction={this.state.sortDirection}
                        onClick={() => this.handleSort("date")}
                      >
                        Date
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        direction={this.state.sortDirection}
                        onClick={() => this.handleSort("serviceFacilityName")}
                      >
                        Facility
                      </TableSortLabel>
                    </TableCell>
                    {permission === "Service Rep" && (
                      <TableCell>
                        <TableSortLabel
                          direction={this.state.sortDirection}
                          onClick={() => this.handleSort("facilityContactName")}
                        >
                          Facility Contact
                        </TableSortLabel>
                      </TableCell>
                    )}
                    <TableCell>
                      <TableSortLabel
                        direction={this.state.sortDirection}
                        onClick={() => this.handleSort("status")}
                      >
                        Status
                      </TableSortLabel>
                    </TableCell>
                    {permission !== "Service Rep" && (
                      <TableCell>
                        <TableSortLabel
                          direction={this.state.sortDirection}
                          onClick={() => this.handleSort("userName")}
                        >
                          Ordered By
                        </TableSortLabel>
                      </TableCell>
                    )}
                    {permission === "Admin" && (
                      <TableCell>
                        <TableSortLabel
                          direction={this.state.sortDirection}
                          onClick={() => this.handleSort("userRegion")}
                        >
                          Region
                        </TableSortLabel>
                      </TableCell>
                    )}
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>

                {this.state.searched_quotes.map((quote, index) => (
                <ServiceQuoteTableRow
                  singleRow={quote}
                  key={quote.id}
                  rowId={quote.id}
                  collection="quotes"
                  permission={permission}
                />
              ))}
                              
                 


                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    full_quotes: state.firestore.ordered.quotes
  };
};


export default compose(
  connect(
    mapStateToProps
  ),
  firestoreConnect([
    {
      collection: "quotes",
    },
   
  ])
)(QuoteTableAdmin);


