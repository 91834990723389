import React from "react";
import { NavLink } from "react-router-dom";

// Images
import RequestIcon from "../../../../img/icon-upload.svg";
import ActiveIcon from "../../../../img/icon-list.svg";
import ArchiveIcon from "../../../../img/icon-box-open.svg";

const ServiceSraTabs = ({ permission }) => {
  return (
    <div>
      {permission === "Service Rep" ? (
        <div className="tabs">
          <NavLink to="/service/sra/request" activeClassName="active">
            <img src={RequestIcon} alt="Request" />
            Request
          </NavLink>
          <NavLink to="/service/sra/active" activeClassName="active">
            <img src={ActiveIcon} alt="Active" />
            Active
          </NavLink>
        </div>
      ) : (
        <div className="tabs">
          <NavLink to="/service/sra/active" activeClassName="active">
            <img src={ActiveIcon} alt="Active" />
            Active
          </NavLink>
          <NavLink to="/service/sra/archive" activeClassName="active">
            <img src={ArchiveIcon} alt="Archive" />
            Archive
          </NavLink>
        </div>
      )}
    </div>
  );
};

export default ServiceSraTabs;
