export const createSra = (data, collection) => {
  return (dispatch, getState, getFirebase) => {
    const firestore = getFirebase().firestore();
    const profile = getState().firebase.profile;
    const userId = getState().firebase.auth.uid;

    firestore
      .collection(collection)
      .add({
        ...data,
        user: userId,
        userName: profile.name,
        userRegion: profile.region,
        userAddress: profile.address,
        userCity: profile.city,
        userState: profile.state,
        userZip: profile.zip,
        userEmail: profile.email,
        status: "Requested",
        date: new Date()
      })
      .then(() => {
        dispatch({
          type: "CREATE_SRA"
        });
      })
      .catch(err => {
        dispatch({ type: "CREATE_SRA_ERROR", err });
      });
  };
};

export const updateSra = (data, collection) => {
  return (dispatch, getState, getFirebase) => {
    const firestore = getFirebase().firestore();
    let toUpdate;
    toUpdate = { status: data.status };
    firestore
      .collection(collection)
      .doc(data.id)
      .update(toUpdate)
      .then(() => {
        dispatch({
          type: "UPDATE_SRA"
        });
      })
      .catch(err => {
        dispatch({ type: "UPDATE_SRA_ERROR", err });
      });
  };
};
