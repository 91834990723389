import React, { Component } from "react";
import { connect } from "react-redux";

// Actions
import {
  enableUser,
  disableUser,
  deleteUser
} from "../../../store/actions/userActions";

// Components
import UsersDialog from "./UsersDialog";
import UsersRemoveDialog from "./UsersRemoveDialog";
import UsersDeleteDialog from "./UsersDeleteDialog";

// MUI Stuff
import Button from "@material-ui/core/Button";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

// Images
import UpdateIcon from "../../../img/icon-timer.svg";
import RemoveIcon from "../../../img/icon-circle-x.svg";
import RestoreIcon from "../../../img/icon-restore.svg";

class UsersTableRow extends Component {
  state = {
    removeOpen: false,
    deleteOpen: false,
    viewOpen: false
  };

  handleDisable = () => {
    disableUser(this.props.singleRow.id);
  };

  handleEnable = () => {
    enableUser(this.props.singleRow.id);
  };

  handleDelete = () => {
    deleteUser(this.props.singleRow.id);
  };

  openModal = key => () => {
    this.setState({
      [key]: true
    });
  };

  closeModal = key => () => {
    this.setState({
      [key]: false
    });
  };

  render() {
    const { singleRow } = this.props;

    const getRowClass = active => {
      if (!active) {
        return "disabled";
      }
      return "";
    };

    const getRegion = region => {
      if (region) {
        return region;
      }
      return "N/A";
    };

    return (
      <TableRow className={getRowClass(singleRow.active)}>
        <TableCell>
          <Button
            className="expand"
            disabled={!singleRow.active}
            onClick={this.openModal("viewOpen")}
          >
            {singleRow.name}
          </Button>
        </TableCell>
        <TableCell>
          <span>{singleRow.email}</span>
        </TableCell>
        <TableCell>
          <span>{singleRow.permission}</span>
        </TableCell>
        <TableCell>
          <span>{getRegion(singleRow.region)}</span>
        </TableCell>
        <TableCell className="actions">
          {singleRow.active ? (
            <>
              <Button
                className="update"
                variant="contained"
                color="primary"
                onClick={this.openModal("viewOpen")}
              >
                <img src={UpdateIcon} alt="icon" />
                Update
              </Button>
              <Button
                className="remove"
                variant="contained"
                color="primary"
                onClick={this.openModal("removeOpen")}
              >
                <img src={RemoveIcon} alt="icon" />
                Disable
              </Button>
            </>
          ) : (
            <>
              <Button
                className="update"
                variant="contained"
                color="primary"
                onClick={this.handleEnable}
              >
                <img src={RestoreIcon} alt="icon" />
                Enable
              </Button>
              <Button
                className="remove"
                variant="contained"
                color="primary"
                onClick={this.openModal("deleteOpen")}
              >
                <img src={RemoveIcon} alt="icon" />
                Delete
              </Button>
            </>
          )}
        </TableCell>

        <UsersDialog
          singleRow={singleRow}
          open={this.state.viewOpen}
          handleClose={this.closeModal("viewOpen")}
        />
        <UsersRemoveDialog
          docId={singleRow.id}
          open={this.state.removeOpen}
          handleClose={this.closeModal("removeOpen")}
          handleDisable={this.handleDisable}
          name={singleRow.name}
        />
        <UsersDeleteDialog
          docId={singleRow.id}
          open={this.state.deleteOpen}
          handleClose={this.closeModal("deleteOpen")}
          handleDelete={this.handleDelete}
          name={singleRow.name}
        />
      </TableRow>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    disableUser: user => dispatch(disableUser(user)),
    enableUser: user => dispatch(enableUser(user)),
    deleteUser: user => dispatch(deleteUser(user))
  };
};

export default connect(null, mapDispatchToProps)(UsersTableRow);
