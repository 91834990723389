export const updateQuote = data => {
  return (dispatch, getState, getFirebase) => {
    const firestore = getFirebase().firestore();
    // Set variables for dates
    let serviceTerm = data.serviceTerm;
    let serviceTermStartDateRaw = new Date(data.serviceTermStartDate);
    // Recalculate start date to match old format

    let serviceStartDate = new Date(serviceTermStartDateRaw);

    let serviceStartDateYear = serviceStartDate.getFullYear();
    let serviceStartDateMonth = serviceStartDate.getMonth() + 1;
    let serviceStartDateDay = serviceStartDate.getDate();

    let serviceStartDateFormatted =
      serviceStartDateYear +
      "-" +
      serviceStartDateMonth +
      "-" +
      serviceStartDateDay;

    // Recalculate end date to match start date and service term
    let serviceEndDate = new Date(serviceTermStartDateRaw);
    let serviceEndDateYear = serviceEndDate.getFullYear();
    let serviceEndDateNewYear =
      Number(serviceEndDateYear) + Number(serviceTerm);
    let serviceEndDateMonth = serviceEndDate.getMonth() + 1;
    let serviceEndDateDay = serviceEndDate.getDate();
    let serviceEndDateFormatted =
      serviceEndDateNewYear +
      "-" +
      serviceEndDateMonth +
      "-" +
      serviceEndDateDay;
    let serviceTermEndDateRaw = new Date(serviceEndDateFormatted);

    // Reformat expected close date
    let closeDate = new Date(data.closeDate);
    let closeDateYear = closeDate.getFullYear();
    let closeDateMonth = closeDate.getMonth() + 1;
    let closeDateDay = closeDate.getDate();
    let closeDateFormatted =
      closeDateYear + "-" + closeDateMonth + "-" + closeDateDay;
    // Check for values that may be undefined and pass defaults to avoid errors
    let managedBy;
    data.managedBy ? (managedBy = data.managedBy) : (managedBy = "");
    let notes;
    data.notes ? (notes = data.notes) : (notes = "");

    let notes_build;
    data.notes_build ? (notes_build = data.notes_build) : (notes_build = "");

    let customerType;
    data.customerType
      ? (customerType = data.customerType)
      : (customerType = "");
    let customerStatus;
    data.customerStatus
      ? (customerStatus = data.customerStatus)
      : (customerStatus = "");
    // Set up discount variables
    let discountPercent;
    data.discountPercent
      ? (discountPercent = data.discountPercent)
      : (discountPercent = 0);
    let discountMultiplier;
    data.discountPercent > 0
      ? (discountMultiplier = 1 - data.discountPercent / 100)
      : (discountMultiplier = 1);
    // Recalculate totals
    let allScopes = data.microscopes;
    let bronzeValue = 0;
    let silverValue = 0;
    let goldValue = 0;
    let platinumValue = 0;
    allScopes.map(singleScope => {
      bronzeValue += singleScope.bronze;
      silverValue += singleScope.silver;
      goldValue += singleScope.gold;
      platinumValue += singleScope.platinum;
      return;
    });
    // Multiply annual values by the service term and discount for the contract total
    let bronzeValueTotal = bronzeValue * serviceTerm * discountMultiplier;
    let silverValueTotal = silverValue * serviceTerm * discountMultiplier;
    let goldValueTotal = goldValue * serviceTerm * discountMultiplier;
    let platinumValueTotal = platinumValue * serviceTerm * discountMultiplier;

    let additionalContacts;
    data.additionalContacts
      ? (additionalContacts = data.additionalContacts)
      : (additionalContacts = "");

    let quotedFacility;
    data.quotedFacility
      ? (quotedFacility = data.quotedFacility)
      : (quotedFacility = "");

    let quotedFacilityWhyNo;
    data.quotedFacilityWhyNo
      ? (quotedFacilityWhyNo = data.quotedFacilityWhyNo)
      : (quotedFacilityWhyNo = "");

    let existingService;
    data.existingService
      ? (existingService = data.existingService)
      : (existingService = "");

    let penteroKinevo;
    data.penteroKinevo
      ? (penteroKinevo = data.penteroKinevo)
      : (penteroKinevo = "");

    let penteroKinevoQuoted;
    data.penteroKinevoQuoted
      ? (penteroKinevoQuoted = data.penteroKinevoQuoted)
      : (penteroKinevoQuoted = "");

    let toUpdate = {
      serviceFacilityName: data.serviceFacilityName,
      facilityContactName: data.facilityContactName,
      facilityContactPhone: data.facilityContactPhone,
      facilityContactEmail: data.facilityContactEmail,

      facilityContactTitle: data.facilityContactTitle
        ? data.facilityContactTitle
        : "",

      serviceFacilityAddress: data.serviceFacilityAddress,
      serviceFacilityAddressCity: data.serviceFacilityAddressCity,
      serviceFacilityAddressState: data.serviceFacilityAddressState,
      serviceFacilityAddressZip: data.serviceFacilityAddressZip,
      billingSame: data.billingSame,
      billFacilityName: data.billFacilityName,
      billFacilityAddress: data.billFacilityAddress,
      billFacilityAddressCity: data.billFacilityAddressCity,
      billFacilityAddressState: data.billFacilityAddressState,
      billFacilityAddressZip: data.billFacilityAddressZip,

      serviceTermStartDate: serviceStartDateFormatted,
      serviceTermStartDateRaw: serviceTermStartDateRaw,

      serviceTermEndDateRaw: serviceTermEndDateRaw,

      serviceTerm: data.serviceTerm,
      purchaseOrder: data.purchaseOrder,

      closeDate: closeDateFormatted,
      closeDateRaw: closeDate,

      customerType: customerType,
      customerStatus: customerStatus,
      managedBy: managedBy,

      status: data.status,
      closed_status: data.closed_status,

      notes: notes,
      notes_build: notes_build,
      coverageBronzeCost: Number(bronzeValueTotal).toFixed(2),
      coverageSilverCost: Number(silverValueTotal).toFixed(2),
      coverageGoldCost: Number(goldValueTotal).toFixed(2),
      coveragePlatinumCost: Number(platinumValueTotal).toFixed(2),
      discountPercent: Number(discountPercent),
      discountMultiplier: Number(discountMultiplier).toFixed(2),
      valueOverride: data.valueOverride,
      contractType: data.contractType,

      additionalContacts: data.additionalContacts
        ? data.additionalContacts
        : "",
      quotedFacility: data.quotedFacility ? data.quotedFacility : "",
      quotedFacilityWhyNo: data.quotedFacilityWhyNo
        ? data.quotedFacilityWhyNo
        : "",
      existingService: data.existingService ? data.existingService : "",
      penteroKinevo: data.penteroKinevo ? data.penteroKinevo : "",
      penteroKinevoQuoted: data.penteroKinevoQuoted
        ? data.penteroKinevoQuoted
        : ""
    };
    firestore
      .collection("quotes")
      .doc(data.id)
      .update(toUpdate)
      .then(() => {
        dispatch({
          type: "UPDATE_QUOTE"
        });
      })
      .catch(err => {
        dispatch({ type: "UPDATE_QUOTE_ERROR", err });
      });
  };
};

export const createQuote = data => {
  return (dispatch, getState, getFirebase) => {
    const firestore = getFirebase().firestore();
    const profile = getState().firebase.profile;
    const userId = getState().firebase.auth.uid;
    // Calculate total annual values for each quote level
    let allScopes = data.microscopes;
    let bronzeValue = 0;
    let silverValue = 0;
    let goldValue = 0;
    let platinumValue = 0;
    allScopes.map(singleScope => {
      bronzeValue += singleScope.bronze;
      silverValue += singleScope.silver;
      goldValue += singleScope.gold;
      platinumValue += singleScope.platinum;
      return;
    });
    // Multiply annual values by the service term for the contract total
    let serviceTerm = data.serviceTerm;
    let bronzeValueTotal = bronzeValue * serviceTerm;
    let silverValueTotal = silverValue * serviceTerm;
    let goldValueTotal = goldValue * serviceTerm;
    let platinumValueTotal = platinumValue * serviceTerm;
    // Recalculate close date to match old format
    let closeDate = new Date(data.closeDateRaw);
    let closeDateYear = closeDate.getFullYear();
    let closeDateMonth = closeDate.getMonth() + 1;
    let closeDateDay = closeDate.getDate();
    let closeDateFormatted =
      closeDateYear + "-" + closeDateMonth + "-" + closeDateDay;
    // Recalculate start date to match old format
    let serviceStartDate = new Date(data.serviceTermStartDateRaw);
    let serviceStartDateYear = serviceStartDate.getFullYear();
    let serviceStartDateMonth = serviceStartDate.getMonth() + 1;
    let serviceStartDateDay = serviceStartDate.getDate();
    let serviceStartDateFormatted =
      serviceStartDateYear +
      "-" +
      serviceStartDateMonth +
      "-" +
      serviceStartDateDay;
    // Create new Firestore doc
    firestore
      .collection("quotes")
      .add({
        ...data,
        closeDate: closeDateFormatted,
        serviceTermStartDate: serviceStartDateFormatted,
        coverageBronzeCost: Number(bronzeValueTotal).toFixed(2),
        coverageSilverCost: Number(silverValueTotal).toFixed(2),
        coverageGoldCost: Number(goldValueTotal).toFixed(2),
        coveragePlatinumCost: Number(platinumValueTotal).toFixed(2),
        discountPercent: 0,
        discountMultiplier: 1,
        user: userId,
        userName: profile.name,
        userRegion: profile.region,
        userAddress: profile.address,
        userCity: profile.city,
        userState: profile.state,
        userZip: profile.zip,
        userEmail: profile.email,
        status: "Open",
        date: new Date(),
        notes: ""
      })
      .then(() => {
        dispatch({
          type: "CREATE_QUOTE",
          inventory: data
        });
      })
      .catch(err => {
        dispatch({ type: "CREATE_QUOTE_ERROR", err });
      });
  };
};

export const quoteReport = data => {
  return (dispatch, getState, getFirebase) => {
    console.log("quoteReport Called", data);
  };
};
