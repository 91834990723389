const initState = {};

const inventoryReducer = (state = initState, action) => {
  switch (action.type) {
    case "CREATE_INVENTORY":
      console.log("Inventory Request Created");
      return state;
    case "CREATE_INVENTORY_ERROR":
      console.log("Error Creating Inventory");
      return state;
    case "UPDATE_INVENTORY":
      console.log("Inventory Request Updated");
      return state;
    case "UPDATE_INVENTORY_ERROR":
      console.log("Error Updating Inventory");
      return state;
    case "ADD_USER_DATA_INVENTORY":
      console.log("User Info Added");
      return state;
    case "ADD_USER_DATA_INVENTORY_ERROR":
      console.log("Error Adding User Info");
      return state;
    default:
      return state;
  }
};

export default inventoryReducer;
