const initState = {
  authError: null
};

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case "LOGIN_ERROR":
      console.log("Login Error");
      var errorMessage;
      if (action.err.code === "auth/user-not-found") {
        errorMessage =
          "Email address not found. Please check for spelling or try another.";
      } else if (action.err.code === "auth/wrong-password") {
        errorMessage = "Incorrect password. Please try again.";
      } else {
        errorMessage = action.err.message;
      }
      return {
        ...state,
        authError: "Login Failed",
        authErrorMessage: errorMessage
      };
    case "LOGIN_SUCCESS":
      console.log("Login Success");
      return {
        ...state,
        authError: null
      };
    case "SIGNOUT_SUCCESS":
      console.log("Logout Success");
      return state;
    case "CREATE_USER_SUCCESS":
      console.log("New User Success");
      return {
        ...state,
        authError: null,
        newUser: true
      };
    case "CREATE_USER_ERROR":
      console.log("New User Error");
      return {
        ...state,
        authError: action.err.message,
        newUser: false
      };
    case "UPDATE_USER":
      console.log("User Updated");
      return {
        ...state,
        newUser: false
      };
    case "PASSWORD_RESET_SUCCESS":
      return {
        ...state,
        resetEmailSent: true
      };
    case "PASSWORD_RESET_ERROR":
      return {
        ...state,
        resetEmailError: action.err,
        resetEmailSent: false
      };
    default:
      return state;
  }
};

export default authReducer;
