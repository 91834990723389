const initState = {};

const salesVideoReducer = (state = initState, action) => {
  switch (action.type) {
    case "CREATE_SALES_VIDEO":
      console.log("Video Doc Added", action.salesvideo);
      return state;
    default:
      return state;
  }
};

export default salesVideoReducer;
