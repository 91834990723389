import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect, isLoaded } from "react-redux-firebase";

// Components
import LoadingBlock from "../../../global/LoadingBlock";
import ServiceInventorySingleEdit from "./ServiceInventorySingleEdit";

const ServiceInventorySingle = ({ permission, inventory, id }) => {
  // If everything went to plan
  if (!isLoaded(inventory)) {
    return <LoadingBlock />;
  }
  return (
    <div>
      {inventory.map(singleInventory => (
        <ServiceInventorySingleEdit
          singleRow={singleInventory}
          key={singleInventory.id}
          rowId={singleInventory.id}
          collection="inventory"
          permission={permission}
        />
      ))}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    inventory: state.firestore.ordered.inventory
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect(props => {
    return [
      {
        collection: "inventory",
        doc: props.id
      }
    ];
  })
)(ServiceInventorySingle);
