import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

// Actions
import { resetPassword } from "../store/actions/authActions";

// Material UI
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

// Components
import LoadingBlock from "./global/LoadingBlock";

class reset extends Component {
  state = {
    email: "",
    alert: "",
    isSending: false
  };
  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };
  handleSubmit = e => {
    e.preventDefault();
    if (this.state.email === "") {
      this.setState({ alert: "Please enter your email address" });
    } else {
      this.props.resetPassword(this.state.email);
      this.setState({ alert: "" });
      this.setState({ isSending: true });
    }
  };

  // Handle errors or confirmation
  componentDidUpdate(prevProps) {
    if (this.props.resetEmailSent !== prevProps.resetEmailSent) {
      if (this.props.resetEmailSent) {
        this.setState({
          isSending: false
        });
      }
    }
    if (this.props.resetEmailError !== prevProps.resetEmailError) {
      console.log(this.props.resetEmailError);
      if (this.props.resetEmailError.code === "auth/user-not-found") {
        this.setState({
          alert:
            "We can't find an account that uses that email address. Please check the spelling and try entering it again."
        });
      } else {
        this.setState({
          alert: this.props.resetEmailError.message
        });
      }
      this.setState({
        isSending: false
      });
    }
  }

  render() {
    const { auth, resetEmailSent } = this.props;

    // Redirect if already logged in
    if (auth.uid) {
      return <Redirect to="/service" />;
    }

    return (
      <div>
        <h1>Reset Password</h1>
        {this.state.alert !== "" && (
          <div className="error-message">
            <p>{this.state.alert}</p>
          </div>
        )}
        {resetEmailSent === true ? (
          <div>
            <p>Password reset email sent.</p>
            <p>
              <a href="/login">Back to login</a>
            </p>
          </div>
        ) : (
          <>
            {this.state.isSending && <LoadingBlock />}
            <form onSubmit={this.handleSubmit}>
              <div className="field">
                <TextField
                  id="email"
                  label="Email"
                  type="email"
                  variant="filled"
                  required
                  onChange={this.handleChange}
                />
              </div>
              <div className="field">
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={this.state.isSending}
                >
                  Send Reset Email
                </Button>
              </div>
            </form>
            <p>
              <a href="/login">Return to login</a>.
            </p>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    resetEmailSent: state.auth.resetEmailSent,
    resetEmailError: state.auth.resetEmailError,
    auth: state.firebase.auth
  };
};

const mapDispatchToProps = dispatch => {
  return {
    resetPassword: email => dispatch(resetPassword(email))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(reset);
