import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { isLoaded, isEmpty } from "react-redux-firebase";

// Components
import LoadingBlock from "../../global/LoadingBlock";
import ServiceTitleBar from "../../global/ServiceTitleBar";
import ServiceQuoteTable from "./components/ServiceQuoteTable";
import ServiceQuoteTabs from "./components/ServiceQuoteTabs";

class serviceQuoteOpen extends Component {
  state = {
    perPage: 300
  };
  showMore = current => {
    let more = Number(current) + 300;
    this.setState({ perPage: more });
  };
  render() {
    const { auth, profile } = this.props;
    // If user isn't logged in
    if (isEmpty(auth)) {
      return <Redirect to="/login" />;
    }
    // If everything went to plan
    if (profile.isLoaded) {
      let firestoreQuery;
      if (profile.permission === "Service Rep") {
        firestoreQuery = [
          ["user", "==", auth.uid],
          ["status", "==", "Open"]
        ];
      }
      if (profile.permission === "Manager") {
        firestoreQuery = [
          ["userRegion", "==", profile.region],
          ["status", "==", "Open"]
        ];
      }
      if (profile.permission === "Admin") {
        firestoreQuery = [["status", "==", "Open"]];
      }
      return (
        <div>
          <ServiceTitleBar />
          <h1
            style={{
              marginBottom: profile.permission == "Admin" ? "5px" : "30px"
            }}
          >
            View Service Contract Quotes
          </h1>

          <a
            style={{ marginRight: "30px", display: "inline-block" }}
            href="/service/quote/search"
          >
            SEARCH
          </a>

          {profile.permission == "Admin" ? (
            <a
              style={{ marginBottom: "30px", display: "inline-block" }}
              href="/service/quote/csv"
            >
              CSV REPORTS
            </a>
          ) : (
            ""
          )}

          <ServiceQuoteTable
            perPage={this.state.perPage}
            showMore={this.showMore}
            permission={profile.permission}
            firestoreQuery={firestoreQuery}
          />
          <ServiceQuoteTabs />
        </div>
      );
    }
    // If profile and auth aren't loaded
    return <LoadingBlock />;
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile
  };
};

export default connect(mapStateToProps, null)(serviceQuoteOpen);
