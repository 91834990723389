import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { isEmpty } from "react-redux-firebase";

// Components
import LoadingBlock from "../../global/LoadingBlock";
import ServiceTitleBar from "../../global/ServiceTitleBar";
import ServiceSraSingle from "./components/ServiceSraSingle";

const serviceSraSingle = ({ match, auth, profile }) => {
  // If user isn't logged in
  if (isEmpty(auth)) {
    return <Redirect to="/login" />;
  }
  // If everything went to plan
  return (
    <div>
      <ServiceTitleBar />
      <h1>SRA Request</h1>
      <ServiceSraSingle permission={profile.permission} id={match.params.id} />
    </div>
  );
  // If profile and auth aren't loaded
  return <LoadingBlock />;
};

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile
  };
};

export default connect(mapStateToProps, null)(serviceSraSingle);
