import React from "react";
import { NavLink } from "react-router-dom";

// Images
import UsersIcon from "../../img/icon-users.svg";
import QuoteIcon from "../../img/icon-write.svg";
import InventoryIcon from "../../img/icon-box-closed.svg";
import SRAIcon from "../../img/icon-tools.svg";
import PartsIcon from "../../img/icon-list.svg";
import PasswordIcon from "../../img/icon-lock-open.svg";
import VideoIcon from "../../img/icon-video.svg";
import CashIcon from "../../img/icon-cash.svg";

const ServiceAdmin = ({ showPass, showVideo, showDash, showPricing }) => {
  return (
    <div className="nav-blocks">
      <NavLink to="/users/manage">
        <div className="nav-blocks--block">
          <span className="icon">
            <img src={UsersIcon} alt="Users" />
          </span>
          <span className="label">Users</span>
        </div>
      </NavLink>
      <NavLink to="/service/quote/open">
        <div className="nav-blocks--block">
          <span className="icon">
            <img src={QuoteIcon} alt="Service Contract Quote" />
          </span>
          <span className="label">
            Contract
            <br />
            Quote
          </span>
        </div>
      </NavLink>
      <NavLink to="/service/inventory/active">
        <div className="nav-blocks--block">
          <span className="icon">
            <img src={InventoryIcon} alt="Inventory Requests" />
          </span>
          <span className="label">
            Inventory
            <br />
            Requests
          </span>
        </div>
      </NavLink>
      <NavLink to="/service/sra/active">
        <div className="nav-blocks--block">
          <span className="icon">
            <img src={SRAIcon} alt="SRA Requests" />
          </span>
          <span className="label">
            SRA
            <br />
            Requests
          </span>
        </div>
      </NavLink>
      <NavLink to="/service/parts/manage">
        <div className="nav-blocks--block">
          <span className="icon">
            <img src={PartsIcon} alt="Parts Price List" />
          </span>
          <span className="label">
            Parts
            <br />
            Price List
          </span>
        </div>
      </NavLink>
      {showPass && (
        <NavLink to="/service/password">
          <div className="nav-blocks--block">
            <span className="icon">
              <img src={PasswordIcon} alt="Password Generator" />
            </span>
            <span className="label">
              Password
              <br />
              Generator
            </span>
          </div>
        </NavLink>
      )}
      {showVideo && (
        <NavLink to="/service/video/view">
          <div className="nav-blocks--block">
            <span className="icon">
              <img src={VideoIcon} alt="Videos" />
            </span>
            <span className="label">
              Training
              <br />
              Videos
            </span>
          </div>
        </NavLink>
      )}
      {showPricing && (
        <NavLink to="/service/pricing">
          <div className="nav-blocks--block">
            <span className="icon">
              <img src={CashIcon} alt="Pricing" />
            </span>
            <span className="label">
              Manage
              <br />
              Pricing
            </span>
          </div>
        </NavLink>
      )}
      {/*
      {showPass.showDash === true && (
        <NavLink to="/service/report">
          <div className="nav-blocks--block">
            <span className="icon">
              <img src={PasswordIcon} alt="Dashboard" />
            </span>
            <span className="label">
              Contracts
              <br />
              Dashboard
            </span>
          </div>
        </NavLink>
      )}
      */}
    </div>
  );
};

export default ServiceAdmin;
