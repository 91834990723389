import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import firebase from "@firebase/app";
import "@firebase/storage";
import FileUploader from "react-firebase-file-uploader";

// Actions
import { createVideo } from "../../../store/actions/videoActions";

// Material UI
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

// Components
import ServiceTitleBar from "../../global/ServiceTitleBar";
import ServiceVideoTabs from "./components/ServiceVideoTabs";

class serviceVideoCreate extends Component {
  state = {
    title: "",
    order: "",
    video: [],
    isUploading: false,
    progress: 0
  };
  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };
  handleUploadStart = () => this.setState({ isUploading: true, progress: 0 });
  handleProgress = progress => this.setState({ progress });
  handleUploadError = error => {
    this.setState({ isUploading: false });
    // Handle upload error
    console.error(error);
  };
  handleUploadSuccess = async filename => {
    const downloadURL = await firebase
      .storage()
      .ref("serviceinventory")
      .child(filename)
      .getDownloadURL();
    this.setState(oldState => ({
      video: [...oldState.video, downloadURL],
      uploadProgress: 100,
      isUploading: false
    }));
  };
  handleSubmit = e => {
    e.preventDefault();
    this.props.createVideo(this.state);
    this.props.history.push("/service/video/manage");
  };
  render() {
    const { auth, profile } = this.props;
    if (!auth.uid) return <Redirect to="/login" />;
    let progressBarStyle = {
      width: this.state.progress + "%"
    };
    return (
      <div>
        <ServiceTitleBar />
        <ServiceVideoTabs />
        <h1>Upload Video</h1>
        <form onSubmit={this.handleSubmit}>
          <div className="field half">
            <TextField
              id="title"
              label="Video Title"
              type="text"
              variant="filled"
              required
              onChange={this.handleChange}
            />
          </div>
          <div className="field half">
            <TextField
              id="order"
              label="Video Order"
              type="number"
              variant="filled"
              required
              onChange={this.handleChange}
            />
          </div>
          <div className="field half">
            <p>
              <strong>Upload video in mp4 format:</strong>
            </p>
            <div className="upload">
              <label className="file-upload">
                Upload Video
                <FileUploader
                  name="video"
                  randomizeFilename
                  storageRef={firebase.storage().ref("serviceinventory")}
                  onUploadStart={this.handleUploadStart}
                  onUploadError={this.handleUploadError}
                  onUploadSuccess={this.handleUploadSuccess}
                  onProgress={this.handleProgress}
                  multiple
                />
              </label>
              {this.state.isUploading && (
                <div className="upload-progress">
                  <h5>Uploading</h5>
                  <div className="upload-progress--background">
                    <div
                      className="upload-progress--bar"
                      style={progressBarStyle}
                    ></div>
                  </div>
                </div>
              )}
              {this.state.video[0] && (
                <div className="upload-preview">
                  <p>Video Uploaded:</p>
                  <div className="files">
                    {this.state.video.map((downloadURL, i) => {
                      return (
                        <a
                          href={downloadURL}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Video Link
                        </a>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="field">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={this.state.isUploading}
            >
              Save
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createVideo: data => dispatch(createVideo(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(serviceVideoCreate);
