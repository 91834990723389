import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { isLoaded, isEmpty } from "react-redux-firebase";

// Components
import LoadingBlock from "../../global/LoadingBlock";
import ServiceTitleBar from "../../global/ServiceTitleBar";
import ServiceQuoteTableSearch from "./components/ServiceQuoteTableSearch";
import ServiceQuoteTabs from "./components/ServiceQuoteTabs";

class serviceQuoteSearch extends Component {
  state = {
    perPage: 50
  };
  showMore = current => {
    let more = Number(current) + 50;
    this.setState({ perPage: more });
  };

  

  
  render() {
    const { auth, profile } = this.props;
    // If user isn't logged in
    if (isEmpty(auth)) {
      return <Redirect to="/login" />;
    }
    // If everything went to plan
    if (profile.isLoaded) {
      let firestoreQuery;
      if (profile.permission === "Service Rep") {
        firestoreQuery = [
          ["user", "==", auth.uid],
          ['deleted', '==', false],
        ];
      }
      if (profile.permission === "Manager") {
        firestoreQuery = [
          ["userRegion", "==", profile.region],
          ['deleted', '==', false],
        ];
      }
      if (profile.permission === "Admin") {
        firestoreQuery = [
          ['deleted', '==', false],
        ];
      }
      return (
        <div>
          
          <h1 style={ { marginBottom: '30px' } }>Search Contract Quotes</h1>
          <a style={ { display:'block',marginBottom:'30px'} } href='/service/quote/open'>CONTRACT QUOTES</a>

          <ServiceQuoteTableSearch
            perPage={this.state.perPage}
            showMore={this.showMore}
            permission={profile.permission}
            firestoreQuery={firestoreQuery}
          />
          
        </div>
      );
    }
    // If profile and auth aren't loaded
    return <LoadingBlock />;
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile
  };
};

export default connect(
  mapStateToProps,
  null
)(serviceQuoteSearch);
