import React, { Component } from "react";
import { NavLink } from "react-router-dom";

// Images
import BuildIcon from "../../../img/icon-upload.svg";
import ViewIcon from "../../../img/icon-list.svg";

class UsersTabs extends Component {
  render() {
    return (
      <div className="tabs">
        <NavLink to="/users/create" activeClassName="active">
          <img src={BuildIcon} alt="Build" />
          Create
        </NavLink>
        <NavLink to="/users/manage" activeClassName="active">
          <img src={ViewIcon} alt="View" />
          Manage
        </NavLink>
      </div>
    );
  }
}

export default UsersTabs;
