import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import firebase from "@firebase/app";
import "@firebase/storage";
import FileUploader from "react-firebase-file-uploader";

// Actions
import { createInventory } from "../../../store/actions/inventoryActions";

// Material UI
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

// Components
import ServiceTitleBar from "../../global/ServiceTitleBar";
import ServiceInventoryTabs from "./components/ServiceInventoryTabs";

class serviceInventoryRequest extends Component {
  state = {
    make: "",
    model: "",
    part: "",
    shippingspeed: "",
    description: "",
    shippingAccountNumber: "",
    billingAccountNumber: "",
    facilityName: "",
    phoneNumber: "",
    email: "",
    requestType: "",
    photo: [],
    shipto: true,
    shippingAddress: "",
    shippingCity: "",
    shippingState: "",
    shippingZip: "",
    isUploading: false,
    progress: 0
  };
  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };
  handleSelectChange = name => e => {
    this.setState({
      [name]: e.target.value
    });
  };
  handleCheckboxChange = e => {
    this.setState({
      [e.target.id]: e.target.checked
    });
  };
  handleUploadStart = () => this.setState({ isUploading: true, progress: 0 });
  handleProgress = progress => this.setState({ progress });
  handleUploadError = error => {
    this.setState({ isUploading: false });
    // Handle upload error
    console.error(error);
  };
  handleUploadSuccess = async filename => {
    const downloadURL = await firebase
      .storage()
      .ref("serviceinventory")
      .child(filename)
      .getDownloadURL();
    this.setState(oldState => ({
      photo: [...oldState.photo, downloadURL],
      uploadProgress: 100,
      isUploading: false
    }));
  };
  handleSubmit = e => {
    e.preventDefault();
    this.props.createInventory(this.state, "inventory");
    this.props.history.push("/service/inventory/active");
  };
  render() {
    const { auth, profile } = this.props;
    if (!auth.uid) return <Redirect to="/login" />;
    let progressBarStyle = {
      width: this.state.progress + "%"
    };
    return (
      <div>
        <ServiceTitleBar />
        <ServiceInventoryTabs permission={profile.permission} />
        <h1>Request Inventory</h1>
        <form onSubmit={this.handleSubmit}>
          <div className="field half">
            <TextField
              id="make"
              label="Microscope Make"
              type="text"
              variant="filled"
              required
              onChange={this.handleChange}
            />
          </div>
          <div className="field half">
            <TextField
              id="model"
              label="Microscope Model"
              type="text"
              variant="filled"
              required
              onChange={this.handleChange}
            />
          </div>
          <div className="field half">
            <TextField
              id="part"
              label="Part Number (if known)"
              type="text"
              variant="filled"
              onChange={this.handleChange}
            />
          </div>
          <div className="field half">
            <FormControl variant="filled">
              <InputLabel id="shippingspeed-label">Shipping Speed *</InputLabel>
              <Select
                id="shippingspeed"
                labelId="shippingspeed-label"
                variant="filled"
                label="Shipping Speed"
                value={this.state.shippingspeed}
                required
                onChange={this.handleSelectChange("shippingspeed")}
              >
                <MenuItem value="Ground">Ground</MenuItem>
                <MenuItem value="Express Saver">Express Saver</MenuItem>
                <MenuItem value="2nd Day">2nd Day</MenuItem>
                <MenuItem value="2nd Day AM">2nd Day AM</MenuItem>
                <MenuItem value="Standard Overnight">
                  Standard Overnight
                </MenuItem>
                <MenuItem value="Priority Overnight">
                  Priority Overnight
                </MenuItem>
                <MenuItem value="Saturday Delivery">Saturday Delivery</MenuItem>
                <MenuItem value="Saturday Priority">Saturday Priority</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="field">
            <TextField
              id="description"
              label="Part Description"
              variant="filled"
              multiline={true}
              rows={5}
              rowsMax={5}
              required
              onChange={this.handleChange}
            ></TextField>
          </div>
          <div className="field half">
            <TextField
              id="shippingAccountNumber"
              label="Shipping Account Number"
              type="text"
              maxLength="100"
              variant="filled"
              onChange={this.handleChange}
            />
          </div>
          <div className="field half">
            <TextField
              id="billingAccountNumber"
              label="Billing Account Number"
              type="text"
              variant="filled"
              required
              onChange={this.handleChange}
            />
          </div>
          <div className="field half">
            <TextField
              id="facilityName"
              label="Facility Name"
              type="text"
              variant="filled"
              onChange={this.handleChange}
            />
          </div>
          <div className="field half">
            <TextField
              id="phoneNumber"
              label="Phone Number"
              type="tel"
              variant="filled"
              maxLength="30"
              required
              onChange={this.handleChange}
            />
          </div>
          <div className="field half">
            <TextField
              id="email"
              label="Email"
              type="email"
              variant="filled"
              maxLength="50"
              required
              onChange={this.handleChange}
            />
          </div>
          <div className="field half">
            <FormControl variant="filled">
              <InputLabel id="requestType-label">Type of Request *</InputLabel>
              <Select
                id="requestType"
                labelId="requestType-label"
                variant="filled"
                label="Type of Request"
                required
                value={this.state.requestType}
                onChange={this.handleSelectChange("requestType")}
              >
                <MenuItem value="sales">Sales</MenuItem>
                <MenuItem value="service">Service</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="field half">
            <div className="upload">
              <label className="file-upload">
                Add Part Photo(s)
                <FileUploader
                  name="photo"
                  randomizeFilename
                  storageRef={firebase.storage().ref("serviceinventory")}
                  onUploadStart={this.handleUploadStart}
                  onUploadError={this.handleUploadError}
                  onUploadSuccess={this.handleUploadSuccess}
                  onProgress={this.handleProgress}
                  multiple
                />
              </label>
              {this.state.isUploading && (
                <div className="upload-progress">
                  <h5>Uploading</h5>
                  <div className="upload-progress--background">
                    <div
                      className="upload-progress--bar"
                      style={progressBarStyle}
                    ></div>
                  </div>
                </div>
              )}
              {this.state.photo[0] && (
                <div className="upload-preview">
                  <p>Photo(s) Uploaded:</p>
                  <div className="files">
                    {this.state.photo.map((downloadURL, i) => {
                      return (
                        <a
                          href={downloadURL}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img key={i} src={downloadURL} alt="" />
                        </a>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="field">
            <h2>Ship Inventory to:</h2>
          </div>
          <div className="field">
            <label>
              <Checkbox
                id="shipto"
                label="My Home Address"
                checked={this.state.shipto}
                color="primary"
                onChange={this.handleCheckboxChange}
              />
              <span className="checkbox-label">My Home address</span>
            </label>
          </div>
          {this.state.shipto === false && (
            <div className="toggle">
              <div className="field">
                <TextField
                  id="shippingAddress"
                  label="Shipping Address"
                  type="text"
                  variant="filled"
                  onChange={this.handleChange}
                />
              </div>
              <div className="field half">
                <TextField
                  id="shippingCity"
                  label="City"
                  type="text"
                  variant="filled"
                  onChange={this.handleChange}
                />
              </div>
              <div className="field quarter">
                <FormControl variant="filled">
                  <InputLabel id="shippingState-label">State</InputLabel>
                  <Select
                    id="shippingState"
                    labelId="shippingState-label"
                    variant="filled"
                    label="State"
                    value={this.state.shippingState}
                    onChange={this.handleSelectChange("shippingState")}
                  >
                    <MenuItem value="AL">Alabama</MenuItem>
                    <MenuItem value="AK">Alaska</MenuItem>
                    <MenuItem value="AZ">Arizona</MenuItem>
                    <MenuItem value="AR">Arkansas</MenuItem>
                    <MenuItem value="CA">California</MenuItem>
                    <MenuItem value="CO">Colorado</MenuItem>
                    <MenuItem value="CT">Connecticut</MenuItem>
                    <MenuItem value="DE">Delaware</MenuItem>
                    <MenuItem value="DC">District Of Columbia</MenuItem>
                    <MenuItem value="FL">Florida</MenuItem>
                    <MenuItem value="GA">Georgia</MenuItem>
                    <MenuItem value="HI">Hawaii</MenuItem>
                    <MenuItem value="ID">Idaho</MenuItem>
                    <MenuItem value="IL">Illinois</MenuItem>
                    <MenuItem value="IN">Indiana</MenuItem>
                    <MenuItem value="IA">Iowa</MenuItem>
                    <MenuItem value="KS">Kansas</MenuItem>
                    <MenuItem value="KY">Kentucky</MenuItem>
                    <MenuItem value="LA">Louisiana</MenuItem>
                    <MenuItem value="ME">Maine</MenuItem>
                    <MenuItem value="MD">Maryland</MenuItem>
                    <MenuItem value="MA">Massachusetts</MenuItem>
                    <MenuItem value="MI">Michigan</MenuItem>
                    <MenuItem value="MN">Minnesota</MenuItem>
                    <MenuItem value="MS">Mississippi</MenuItem>
                    <MenuItem value="MO">Missouri</MenuItem>
                    <MenuItem value="MT">Montana</MenuItem>
                    <MenuItem value="NE">Nebraska</MenuItem>
                    <MenuItem value="NV">Nevada</MenuItem>
                    <MenuItem value="NH">New Hampshire</MenuItem>
                    <MenuItem value="NJ">New Jersey</MenuItem>
                    <MenuItem value="NM">New Mexico</MenuItem>
                    <MenuItem value="NY">New York</MenuItem>
                    <MenuItem value="NC">North Carolina</MenuItem>
                    <MenuItem value="ND">North Dakota</MenuItem>
                    <MenuItem value="OH">Ohio</MenuItem>
                    <MenuItem value="OK">Oklahoma</MenuItem>
                    <MenuItem value="OR">Oregon</MenuItem>
                    <MenuItem value="PA">Pennsylvania</MenuItem>
                    <MenuItem value="RI">Rhode Island</MenuItem>
                    <MenuItem value="SC">South Carolina</MenuItem>
                    <MenuItem value="SD">South Dakota</MenuItem>
                    <MenuItem value="TN">Tennessee</MenuItem>
                    <MenuItem value="TX">Texas</MenuItem>
                    <MenuItem value="UT">Utah</MenuItem>
                    <MenuItem value="VT">Vermont</MenuItem>
                    <MenuItem value="VA">Virginia</MenuItem>
                    <MenuItem value="WA">Washington</MenuItem>
                    <MenuItem value="WV">West Virginia</MenuItem>
                    <MenuItem value="WI">Wisconsin</MenuItem>
                    <MenuItem value="WY">Wyoming</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="field quarter">
                <TextField
                  id="shippingZip"
                  label="Zip"
                  type="text"
                  variant="filled"
                  onChange={this.handleChange}
                />
              </div>
            </div>
          )}
          <div className="field">
            <Button type="submit" variant="contained" color="primary">
              Submit Request
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createInventory: data => dispatch(createInventory(data, "inventory"))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(serviceInventoryRequest);
