const initState = {};

const servicePartsReducer = (state = initState, action) => {
  switch (action.type) {
    case "CREATE_SERVICE_PART":
      console.log("Part Pricing Added", action.salespricing);
      return state;
    default:
      return state;
  }
};

export default servicePartsReducer;
