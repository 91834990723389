import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect, isLoaded, isEmpty } from "react-redux-firebase";
import _ from "lodash";
import { invertDirection } from "../../../../util/functions";

// Components
import LoadingBlock from "../../../global/LoadingBlock";
import ServiceInventoryTableRow from "./ServiceInventoryTableRow";
import ServiceInventoryExportCsv from "./ServiceInventoryExportCsv";

// MUI Stuff
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Button from "@material-ui/core/Button";

class ServiceInventoryTable extends Component {
  state = {
    columnToSort: "date",
    sortDirection: "desc"
  };
  handleShowMore = () => {
    this.props.showMore(this.props.perPage);
  };
  handleSort = columnName => {
    this.setState(state => ({
      columnToSort: columnName,
      sortDirection:
        state.columnToSort === columnName
          ? invertDirection(state.sortDirection)
          : "asc"
    }));
  };
  render() {
    const { inventory, perPage, firestoreQuery, permission } = this.props;
    const orderedInventory = _.orderBy(
      inventory,
      [this.state.columnToSort],
      [this.state.sortDirection]
    );
    return (
      <div>
        {!isLoaded(inventory) ? (
          <LoadingBlock />
        ) : isEmpty(inventory) ? (
          <h2>No Inventory Requests Found</h2>
        ) : (
          <div>
            {permission === "Admin" && (
              <ServiceInventoryExportCsv inventory={inventory} />
            )}
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <TableSortLabel
                        direction={this.state.sortDirection}
                        onClick={() => this.handleSort("date")}
                      >
                        Date
                      </TableSortLabel>
                    </TableCell>
                    {permission !== "Service Rep" && (
                      <TableCell>
                        <TableSortLabel
                          direction={this.state.sortDirection}
                          onClick={() => this.handleSort("userName")}
                        >
                          Ordered By
                        </TableSortLabel>
                      </TableCell>
                    )}
                    {permission === "Admin" && (
                      <TableCell>
                        <TableSortLabel
                          direction={this.state.sortDirection}
                          onClick={() => this.handleSort("userRegion")}
                        >
                          Region
                        </TableSortLabel>
                      </TableCell>
                    )}
                    <TableCell>
                      <TableSortLabel
                        direction={this.state.sortDirection}
                        onClick={() => this.handleSort("status")}
                      >
                        Status
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orderedInventory.map((inventory, index) => (
                    <ServiceInventoryTableRow
                      singleRow={inventory}
                      key={inventory.id}
                      rowId={inventory.id}
                      collection="inventory"
                      permission={permission}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    inventory: state.firestore.ordered.inventory
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect(props => {
    return [
      {
        collection: "inventory",
        where: props.firestoreQuery,
        limit: props.perPage,
        orderBy: ["date", "desc"]
      }
    ];
  })
)(ServiceInventoryTable);
