import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect, isLoaded } from "react-redux-firebase";

// Components
import LoadingBlock from "../../../global/LoadingBlock";
import ServiceSraSingleEdit from "./ServiceSraSingleEdit";

const ServiceSraSingle = ({ permission, sras, id }) => {
  // If everything went to plan
  if (!isLoaded(sras)) {
    return <LoadingBlock />;
  }
  return (
    <div>
      {sras.map(singleSra => (
        <ServiceSraSingleEdit
          singleRow={singleSra}
          key={singleSra.id}
          rowId={singleSra.id}
          collection="sras"
          permission={permission}
        />
      ))}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    sras: state.firestore.ordered.sras
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect(props => {
    return [
      {
        collection: "sras",
        doc: props.id
      }
    ];
  })
)(ServiceSraSingle);
