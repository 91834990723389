export const savePricing = (data, region) => {
  return (dispatch, getState, getFirebase) => {
    const firestore = getFirebase().firestore();

    let collection = "pricingus";
    if (region == "uk") {
      collection = "pricinguk";
    }

    // Remove all quote prices
    firestore
      .collection(collection)
      .get()
      .then(res => {
        res.forEach(element => {
          element.ref.delete();
        });
      })
      .then(res => {
        return data.forEach(row => {
          let bronzePrice = 0;
          if (row.bronze) {
            bronzePrice = Number(row.bronze);
          }
          firestore.collection(collection).add({
            make: row.make,
            model: row.model,
            bronze: bronzePrice,
            silver: Number(row.silver),
            gold: Number(row.gold),
            platinum: Number(row.platinum)
          });
        });
      })
      .then(() => {
        dispatch({
          type: "UK_PRICING_SAVED"
        });
      })
      .catch(err => {
        dispatch({ type: "UK_PRICING_ERROR", err });
      });
  };
};
