import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./css/app.css";

// Components
import Navbar from "./components/nav/Navbar";

// Main Pages
import home from "./pages/home";
import login from "./pages/login";
import reset from "./pages/reset";

// Users
import usersManage from "./pages/users/usersManage";
import usersCreate from "./pages/users/usersCreate";

// Service
import service from "./pages/service/service";

// Quotes
import serviceQuoteRequest from "./pages/service/quote/serviceQuoteRequest";
import serviceQuoteOpen from "./pages/service/quote/serviceQuoteOpen";
import serviceQuoteClosed from "./pages/service/quote/serviceQuoteClosed";
import serviceQuoteDeleted from "./pages/service/quote/serviceQuoteDeleted";
import serviceQuoteReportRequest from "./pages/service/quote/serviceQuoteReportRequest";
import serviceQuoteSearch from "./pages/service/quote/serviceQuoteSearch";

// CSV EXPORTER Pages
import serviceQuoteCsv from "./pages/service/quote/serviceQuoteCsv";

// Inventory
import serviceInventoryActive from "./pages/service/inventory/serviceInventoryActive";
import serviceInventoryArchive from "./pages/service/inventory/serviceInventoryArchive";
import serviceInventoryRequest from "./pages/service/inventory/serviceInventoryRequest";
import serviceInventorySingle from "./pages/service/inventory/serviceInventorySingle";
// SRA
import serviceSraActive from "./pages/service/sra/serviceSraActive";
import serviceSraArchive from "./pages/service/sra/serviceSraArchive";
import serviceSraRequest from "./pages/service/sra/serviceSraRequest";
import serviceSraSingle from "./pages/service/sra/serviceSraSingle";

// Parts
import servicePartsView from "./pages/service/parts/servicePartsView";
import servicePartsManage from "./pages/service/parts/servicePartsManage";
import servicePartsRequest from "./pages/service/parts/servicePartsRequest";

// Password
import servicePassword from "./pages/service/password/servicePassword";

// Videos
import serviceVideo from "./pages/service/video/serviceVideo";
import serviceVideoCreate from "./pages/service/video/serviceVideoCreate";
import serviceVideoManage from "./pages/service/video/serviceVideoManage";

// Pricing
import pricingSelect from "./pages/service/pricing/pricingSelect";
import pricingManageUS from "./pages/service/pricing/pricingManageUS";
import pricingManageUK from "./pages/service/pricing/pricingManageUK";

class App extends Component {
  render() {
    return (
      <div className="App">
        <Router>
          <Navbar />
          <div className="content">
            <Switch>
              {/* Main Pages */}
              <Route exact path="/" component={home} />
              <Route exact path="/login" component={login} />
              <Route exact path="/reset" component={reset} />
              {/* User Pages */}
              <Route exact path="/users/manage" component={usersManage} />
              <Route exact path="/users/create" component={usersCreate} />
              {/* Service Pages */}
              <Route exact path="/service" component={service} />

              <Route
                exact
                path="/service/quote/csv"
                component={serviceQuoteCsv}
              />

              <Route
                exact
                path="/service/quote/search"
                component={serviceQuoteSearch}
              />

              {/* Service Quotes */}
              <Route
                exact
                path="/service/quote/request"
                component={serviceQuoteRequest}
              />
              <Route
                exact
                path="/service/quote/open"
                component={serviceQuoteOpen}
              />
              <Route
                exact
                path="/service/quote/closed"
                component={serviceQuoteClosed}
              />

              <Route
                exact
                path="/service/quote/deleted"
                component={serviceQuoteDeleted}
              />

              {/* Service Inventory */}
              <Route
                exact
                path="/service/inventory/active"
                component={serviceInventoryActive}
              />
              <Route
                exact
                path="/service/inventory/archive"
                component={serviceInventoryArchive}
              />
              <Route
                exact
                path="/service/inventory/request"
                component={serviceInventoryRequest}
              />
              <Route
                path="/service/inventory/single/:id"
                component={serviceInventorySingle}
              />
              {/* Service SRA */}
              <Route
                exact
                path="/service/sra/active"
                component={serviceSraActive}
              />
              <Route
                exact
                path="/service/sra/archive"
                component={serviceSraArchive}
              />
              <Route
                exact
                path="/service/sra/request"
                component={serviceSraRequest}
              />
              <Route
                path="/service/sra/single/:id"
                component={serviceSraSingle}
              />
              {/* Service Parts */}
              <Route
                exact
                path="/service/parts/view"
                component={servicePartsView}
              />
              <Route
                exact
                path="/service/parts/manage"
                component={servicePartsManage}
              />
              <Route
                exact
                path="/service/parts/request"
                component={servicePartsRequest}
              />
              <Route
                exact
                path="/service/parts/request"
                component={servicePartsRequest}
              />
              {/* Service Password */}
              <Route
                exact
                path="/service/password"
                component={servicePassword}
              />
              {/* Service Video */}
              <Route
                exact
                path="/service/video/view"
                component={serviceVideo}
              />
              <Route
                exact
                path="/service/video/create"
                component={serviceVideoCreate}
              />
              <Route
                exact
                path="/service/video/manage"
                component={serviceVideoManage}
              />
              {/* Service Pricing */}
              <Route exact path="/service/pricing/" component={pricingSelect} />
              <Route
                exact
                path="/service/pricing/manage-us"
                component={pricingManageUS}
              />
              <Route
                exact
                path="/service/pricing/manage-uk"
                component={pricingManageUK}
              />
              {/* Dashboard */}
              <Route
                exact
                path="/service/quote/report"
                component={serviceQuoteReportRequest}
              />
            </Switch>
          </div>
        </Router>
      </div>
    );
  }
}

export default App;
