export const createVideo = data => {
  return (dispatch, getState, getFirebase) => {
    const firestore = getFirebase().firestore();
    const profile = getState().firebase.profile;
    const userId = getState().firebase.auth.uid;
    firestore
      .collection("videos")
      .add({
        ...data,
        date: new Date()
      })
      .then(() => {
        dispatch({
          type: "CREATE_VIDEO"
        });
      })
      .catch(err => {
        dispatch({ type: "CREATE_VIDEO_ERROR", err });
      });
  };
};

export const updateVideo = data => {
  return (dispatch, getState, getFirebase) => {
    const firestore = getFirebase().firestore();
    let toUpdate;
    toUpdate = { title: data.title, order: data.order, video: data.video };
    firestore
      .collection("videos")
      .doc(data.id)
      .update(toUpdate)
      .then(() => {
        dispatch({
          type: "UPDATE_VIDEO"
        });
      })
      .catch(err => {
        dispatch({ type: "UPDATE_VIDEO_ERROR", err });
      });
  };
};
