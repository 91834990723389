const initState = {};

const salesPricingReducer = (state = initState, action) => {
  switch (action.type) {
    case "CREATE_SALES_PRICING":
      console.log("Pricing Sheet Added", action.salespricing);
      return state;
    default:
      return state;
  }
};

export default salesPricingReducer;
