import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

// Firebase Config
const firebaseConfig = {
  apiKey: "AIzaSyBSPd2utRI5wfQ7nrdFGz_qemO6wU8ajXI",
  authDomain: "prescotts-service-app.firebaseapp.com",
  databaseURL: "https://prescotts-service-app.firebaseio.com",
  projectId: "prescotts-service-app",
  storageBucket: "prescotts-service-app.appspot.com",
  messagingSenderId: "506862542204",
  appId: "1:506862542204:web:874af65c6262a877b3d8f9"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();

// Config for emulator
if (window.location.hostname === "localhost") {
  db.settings({ host: "localhost:5002", ssl: false });
}

export default firebase;
