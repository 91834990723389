import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { isLoaded, isEmpty } from "react-redux-firebase";

// Components
import LoadingBlock from "../../global/LoadingBlock";
import ServiceTitleBar from "../../global/ServiceTitleBar";
import ServiceVideoList from "./components/ServiceVideoList";
import ServiceVideoTabs from "./components/ServiceVideoTabs";

class serviceVideo extends Component {
  state = {
    perPage: 999
  };
  showMore = current => {
    let more = Number(current) + 50;
    this.setState({ perPage: more });
  };
  render() {
    const { auth, profile } = this.props;
    // If user isn't logged in
    if (isEmpty(auth)) {
      return <Redirect to="/login" />;
    }
    // If everything went to plan
    if (profile.isLoaded) {
      let firestoreQuery;
      return (
        <div>
          <ServiceTitleBar />
          <h1>Training Videos</h1>
          <ServiceVideoList
            perPage={this.state.perPage}
            showMore={this.showMore}
            user={auth.uid}
            firestoreQuery={firestoreQuery}
          />
          {profile.permission === "Admin" && <ServiceVideoTabs />}
        </div>
      );
    }
    // If profile and auth aren't loaded
    return <LoadingBlock />;
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile
  };
};

export default connect(mapStateToProps, null)(serviceVideo);
