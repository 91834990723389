import authReducer from "./authReducer";
import globalReducer from "./globalReducer";
import quoteReducer from "./quoteReducer";
import inventoryReducer from "./inventoryReducer";
import sraReducer from "./sraReducer";
import servicePartsReducer from "./servicePartsReducer";
import salesPricingReducer from "./salesPricingReducer";
import salesFormsReducer from "./salesFormsReducer";
import salesVideoReducer from "./salesVideoReducer";
import warrantyReducer from "./warrantyReducer";
import buybackReducer from "./warrantyReducer";
import videoReducer from "./videoReducer";
import pricingReducer from "./pricingReducer";
import { combineReducers } from "redux";
import { firebaseReducer } from "react-redux-firebase";
import { firestoreReducer } from "redux-firestore";

const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  auth: authReducer,
  global: globalReducer,
  quote: quoteReducer,
  inventory: inventoryReducer,
  sras: sraReducer,
  serviceParts: servicePartsReducer,
  salesPricing: salesPricingReducer,
  salesVideo: salesVideoReducer,
  salesForms: salesFormsReducer,
  warranty: warrantyReducer,
  buyback: buybackReducer,
  video: videoReducer,
  pricing: pricingReducer
});

export default rootReducer;
