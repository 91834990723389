import React, { Component } from "react";
import { connect } from "react-redux";
import { firestoreConnect,isLoaded,isEmpty } from "react-redux-firebase";
import { compose } from "redux";
import { CSVLink, CSVDownload } from "react-csv";

// MUI Stuff
import Button from "@material-ui/core/Button";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";

class serviceQuoteCsv extends Component {

  
  state = {
    startDate: new Date(),
    startDateTimestamp: "",
    endDate: new Date().setDate(new Date().getDate() + 30),
    endDateTimestamp: "",
    dateChanged:false,
  }

  handleStartDateChange = date => {
    let end = new Date(date);
    let endDate = end.setDate(end.getDate() + 30);

    this.setState({
      startDate: date,
      endDate: endDate,
      startDateTimestamp: date.getTime() / 1000,
      endDateTimestamp: endDate / 1000,
      dateChanged:true,
    });
   
  };

  handleEndDateChange = date => {
    this.setState({
      endDate: date,
      endDateTimestamp: date.getTime() / 1000,
      dateChanged:true,
    });

  };

    
  render() {
    const { csv,quotes,profile } = this.props;

    
    let snapshotArray = [];
    let closedSnapshotArray = [];
    let open_quotes = [];
    let closed_quotes = [];


    
    if(isLoaded(quotes)){

      
      


      if(this.state.dateChanged){
        // filter by date

        quotes.forEach(element => {

        
         
          
          if(element.status == 'Open' && !element.deleted){
            if(element.date.seconds >= this.state.startDateTimestamp && element.date.seconds <= this.state.endDateTimestamp   ){
              snapshotArray.push(element);
            }
          }
  
          if(element.status == 'Closed - Won' && !element.deleted && element.closeDateRaw){
            if(element.closeDateRaw.seconds >= this.state.startDateTimestamp && element.closeDateRaw.seconds <= this.state.endDateTimestamp   ){
              closedSnapshotArray.push(element);
            }
          }
          
        });




      }else{

        quotes.forEach(element => {
          
          if(element.status == 'Open' && !element.deleted){
            snapshotArray.push(element);
          }
  
          if(element.status == 'Closed - Won' && !element.deleted){
            closedSnapshotArray.push(element);
            
            // closeDateRaw
          }

        });


      }
      
      
      open_quotes = buildCSV(snapshotArray,false);
      closed_quotes = buildCSV(closedSnapshotArray,true);
    
    
    }

    
    
    
    return (
      <div>
        <h1 style={ { marginBottom:'5px'} }>Quotes CSV Reports</h1>
        <a style={ { display:'block',marginBottom:'30px'} } href='/service/quote/open'>CONTRACT QUOTES</a>


       

        <div className="field">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  style={ {  marginRight:'15px' } }
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="startDate"
                  label="Start Date"
                  value={this.state.startDate}
                  onChange={this.handleStartDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                />
          </MuiPickersUtilsProvider>

          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  minDate={this.state.startDate}
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="endDate"
                  label="End Date"
                  value={this.state.endDate}
                  onChange={this.handleEndDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                />
          </MuiPickersUtilsProvider>


        </div>



        

        { open_quotes.length > 0 ? (

          <Button
          style={ { width: '150px', marginRight:'15px' } }
          className="update"
          variant="contained"
          color="primary"
        >
          <CSVLink 
          style={ { color: '#fff' } }
          filename={"open_quotes_report.csv"}
          data={open_quotes}>
          OPEN QUOTES
          </CSVLink>
        </Button>

        
        ) : ''}

          { closed_quotes.length > 0 ? (

          <Button
          style={ { width: '150px', marginRight:'15px'  } }
          className="update"
          variant="contained"
          color="primary"
          >
          <CSVLink 
          style={ { color: '#fff' } }
          filename={"closed_quotes_report.csv"}
          data={closed_quotes}>
          CLOSED QUOTES
          </CSVLink>
          </Button>


          ) : ''}

        {isLoaded(quotes) && profile.permission == 'Admin' ? (
          <Button
            className="update"
            style={ { width: '250px'} }
            variant="contained"
            color="primary"
          >
            <CSVLink 
            style={ { color: '#fff' } }
            filename={"quotes_report.csv"}
            data={quotes}>
            EXPORT RAW QUOTE DATA
            </CSVLink>
          </Button>

          
        ) : ''}
      
          
     
       


       
        
        


      </div>
    );
  }
}



// Function to build CSV file
const buildCSV = (snapshotArray,closed) => {

  
  

  // snapshotArray.forEach(element => {
  //   console.log(element.date.seconds)
  // });

  let csv = [];
  if(closed){
    csv = [['Region','Scopes','TCV','FYCV','FYCV Per Scope']]
  }else{
    csv = [['Region','High Value Total','Low Value Total','Scopes','TCV','FYCV','FYCV Per Scope']]
  }
  

  

  let northEast = snapshotArray.filter(
    ({ userRegion }) => userRegion === "Northeast"
  );

  csv.push(buildCSVRow("Northeast", northEast,closed))

  
  // Southeast
  let southEast = snapshotArray.filter(
    ({ userRegion }) => userRegion === "Southeast"
  );
  csv.push(buildCSVRow("Southeast", southEast, closed))
  
  // South
  let south = snapshotArray.filter(
    ({ userRegion }) => userRegion === "South"
  );
  csv.push(buildCSVRow("South", south, closed))


  // Midwest
  let midWest = snapshotArray.filter(
    ({ userRegion }) => userRegion === "Midwest"
  );
  csv.push(buildCSVRow("Midwest", midWest,closed))
  // West
  let west = snapshotArray.filter(({ userRegion }) => userRegion === "West");
  csv.push(buildCSVRow("West", west,closed))
  // UK
  let unitedKingdom = snapshotArray.filter(
    ({ userRegion }) => userRegion === "United Kingdom"
  );
  csv.push(buildCSVRow("United Kingdom", unitedKingdom,closed))
  // 2. TO DO: Filter by contract types
  // 3. TO DO: Filter by scope types
  // 4. TO DO: Filter by managed types


  // renewal/new
  if(closed){
    csv.push(['','','','','']);
    csv.push(['Contract Type','Scopes','TCV','FYCV','FYCV Per Scope']);
  }else{
    csv.push(['','','','','','','']);
    csv.push(['Contract Type','High Value Total','Low Value Total','Scopes','TCV','FYCV','FYCV Per Scope']);
  
  }
  
  let new_contract = snapshotArray.filter(({ contractType }) => contractType === "New");
  csv.push(buildCSVRow("New", new_contract,closed))

  let renewal_contract = snapshotArray.filter(({ contractType }) => contractType === "Renewal");
  csv.push(buildCSVRow("Renewal", renewal_contract,closed))
  
  
  // managedBy
  
  // New - Rep
  // New - Dept
  // Renewal - Dept
  // Renewal - Rep

  if(closed){
    csv.push(['','','','','']);
    csv.push(['Managed By','Scopes','TCV','FYCV','FYCV Per Scope']);
  }else{
    csv.push(['','','','','','','']);
    csv.push(['Managed By','High Value Total','Low Value Total','Scopes','TCV','FYCV','FYCV Per Scope']);
  }
  
  
  let new_rep = snapshotArray.filter(({ managedBy }) => managedBy === "New - Rep");
  csv.push(buildCSVRow("New - Rep", new_rep,closed))

  let new_dept = snapshotArray.filter(({ managedBy }) => managedBy === "New - Dept");
  csv.push(buildCSVRow("New - Dept", new_dept,closed))

  let renewal_rep = snapshotArray.filter(({ managedBy }) => managedBy === "Renewal - Rep");
  csv.push(buildCSVRow("Renewal - Rep", renewal_rep,closed))

  let renewal_dept = snapshotArray.filter(({ managedBy }) => managedBy === "Renewal - Dept");
  csv.push(buildCSVRow("Renewal - Dept", renewal_dept,closed))
  

  // make filters
  // Northeast Southeast South Midwest United Kingdom
  let pentero = [];
  let others = [];
  snapshotArray.forEach(element => {

    if(element.userRegion === 'Northeast' || element.userRegion === 'Southeast' || element.userRegion === 'South' || element.userRegion === 'West' || element.userRegion === 'Midwest' || element.userRegion === 'United Kingdom'){

    
      if( element.microscopes[0] && element.microscopes[0].make === 'Pentero'){
        pentero.push(element)
      }
      
      if( element.microscopes[0] && element.microscopes[0].make !== 'Pentero'){
        others.push(element)
      }

    }
    


  });

  if(closed){
    csv.push(['','','','','']);
    csv.push(['Make','Scopes','TCV','FYCV','FYCV Per Scope']);
  }else{
    csv.push(['','','','','','','']);
    csv.push(['Make','High Value Total','Low Value Total','Scopes','TCV','FYCV','FYCV Per Scope']);
  }

  

  csv.push(buildCSVRow("Pentero", pentero,closed))
  csv.push(buildCSVRow("Others", others,closed))
  
  
  return csv;
};

// Function to build individual CSV row
const buildCSVRow = (name, data,closed) => {
  // Set default variables
  let highValueTotal = 0;
  let lowValueTotal = 0;
  let fycvTotal = 0;
  let scopesTotal = 0;
  let termTotal = 0;
  let fycvScopeTotal = 0;
  // Iterate through array
  data.forEach(quote => {
    // Set variables for high/low in quote
    let highestValue = 0;
    let lowestValue = 0;
    // Find highest value

    // check closed status
    if(quote.closed_status){

      if(quote.valueOverride){
        highestValue = quote.valueOverride;
      }else{
        switch (quote.closed_status) {
          case 'Bronze':
            highestValue = quote.coverageBronzeCost;
          break;
  
          case 'Silver':
            highestValue = quote.coverageSilverCost;
          break;
  
          case 'Gold':
            highestValue = quote.coverageGoldCost;
          break;
  
          case 'Platinum':
            highestValue = quote.coveragePlatinumCost;
          break;
        
          default:
            highestValue = quote.coverageBronzeCost;
          break;
        }
      }

    }else{
      
        if(quote.valueOverride){
          highestValue = quote.valueOverride;
        }else{
          if (quote.coveragePlatinum) {
            highestValue = quote.coveragePlatinumCost;
          } else if (quote.coverageGold) {
            highestValue = quote.coverageGoldCost;
          } else if (quote.coverageSilver) {
            highestValue = quote.coverageSilverCost;
          } else {
            highestValue = quote.coverageBronzeCost;
          }
        }

       
      
      


    }
    
    
    
    

    // Find lowest value

    if(quote.closed_status){

      if(quote.valueOverride){
        lowestValue = quote.valueOverride;
      }else{
        switch (quote.closed_status) {
          case 'Bronze':
            lowestValue = quote.coverageBronzeCost;
          break;
  
          case 'Silver':
            lowestValue = quote.coverageSilverCost;
          break;
  
          case 'Gold':
            lowestValue = quote.coverageGoldCost;
          break;
  
          case 'Platinum':
            lowestValue = quote.coveragePlatinumCost;
          break;
        
          default:
            lowestValue = quote.coverageBronzeCost;
          break;
        }
      }

    }else{

      if(quote.valueOverride){
        lowestValue = quote.valueOverride;
      }else{
        if (quote.coverageBronze) {
          lowestValue = quote.coverageBronzeCost;
        } else if (quote.coverageSilver) {
          lowestValue = quote.coverageSilverCost;
        } else if (quote.coverageGold) {
          lowestValue = quote.coverageGoldCost;
        } else {
          lowestValue = quote.coveragePlatinumCost;
        }
      }

    }
    
    
    // Add to running totals

    

    highValueTotal += Number.isNaN(parseInt(highestValue)  * parseInt(quote.serviceTerm)) ? 0 : parseInt(highestValue)  * parseInt(quote.serviceTerm);
    lowValueTotal +=  Number.isNaN( parseInt(lowestValue) * parseInt(quote.serviceTerm) ) ? 0 : parseInt(lowestValue) * parseInt(quote.serviceTerm);
    fycvTotal += Number.isNaN( (( parseInt(highestValue) + parseInt(lowestValue)) / 2) * 1 ) ? 0 : (( parseInt(highestValue) + parseInt(lowestValue)) / 2) * 1;
    termTotal += parseInt( Number.isNaN( quote.serviceTerm ) ? 0 : quote.serviceTerm );
    scopesTotal += parseInt( Number.isNaN( quote.microscopes.length ) ? 0 : quote.microscopes.length );

  

  });
  let midTcvTotal = (highValueTotal + lowValueTotal) / 2;
  if (fycvTotal !== 0) {
    fycvScopeTotal = fycvTotal / scopesTotal;
  }

  if(closed){
    return [
      name,
      Math.round(scopesTotal),
      Math.round(midTcvTotal),
      Math.round(fycvTotal),
      Math.round(fycvScopeTotal)
    ];
  }else{

    if(name == 'South'){
      
      return [
        name,
        Math.round(highValueTotal),
        Math.round(lowValueTotal),
        Math.round(scopesTotal),
        Math.round(midTcvTotal),
        Math.round(fycvTotal),
        Math.round(fycvScopeTotal)
      ];
    }else{
      return [
        name,
        Math.round(highValueTotal),
        Math.round(lowValueTotal),
        Math.round(scopesTotal),
        Math.round(midTcvTotal),
        Math.round(fycvTotal),
        Math.round(fycvScopeTotal)
      ];
    }
    
  }
  


};


const mapStateToProps = state => {
  return {
    profile: state.firebase.profile,
    quotes: state.firestore.ordered.quotes,
    csv: 42
  };
};




export default compose(
  connect(
    mapStateToProps
  ),
  firestoreConnect([
    {
      collection: "quotes",
    },
   
  ])
)(serviceQuoteCsv);
