import React, { Component } from "react";
import { NavLink } from "react-router-dom";

// MUI Stuff
import MenuItem from "@material-ui/core/MenuItem";
// import Divider from "@material-ui/core/Divider";

class ManagerMenuLinks extends Component {
  render() {
    return (
      <div>
        {/*
          <MenuItem className="parent">
            <NavLink to="/sales">Sales</NavLink>
          </MenuItem>
          <MenuItem>
            <NavLink to="/sales/warranty/active">Warranty Requests</NavLink>
          </MenuItem>
          <MenuItem>
            <NavLink to="/sales/inventory/active">Inventory Requests</NavLink>
          </MenuItem>
          <MenuItem>
            <NavLink to="/sales/buyback/active">Buyback Requests</NavLink>
          </MenuItem>
          <MenuItem>
            <NavLink to="/sales/schedule/view">Production Schedule</NavLink>
          </MenuItem>
          <MenuItem>
            <NavLink to="/sales/pricing/view">Pricing</NavLink>
          </MenuItem>
          <MenuItem>
            <NavLink to="/sales/video/view">Video</NavLink>
          </MenuItem>
          <MenuItem>
            <NavLink to="/sales/forms/view">Forms</NavLink>
          </MenuItem>
          <Divider />
        */}
        <MenuItem className="parent">
          <NavLink to="/service">Service</NavLink>
        </MenuItem>
        <MenuItem>
          <NavLink to="/service/quote/open">Contract Quote</NavLink>
        </MenuItem>
        <MenuItem>
          <NavLink to="/service/inventory/active">Inventory Requests</NavLink>
        </MenuItem>
        <MenuItem>
          <NavLink to="/service/sra/active">SRA Requests</NavLink>
        </MenuItem>
        <MenuItem>
          <NavLink to="/service/parts/view">Part Pricing</NavLink>
        </MenuItem>
      </div>
    );
  }
}

export default ManagerMenuLinks;
