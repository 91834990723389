import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect, isLoaded, isEmpty } from "react-redux-firebase";
import _ from "lodash";
import { invertDirection } from "../../../util/functions";

// Components
import LoadingBlock from "../../global/LoadingBlock";
import UsersTableRow from "./UsersTableRow";

// MUI Stuff
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Button from "@material-ui/core/Button";

class UsersTable extends Component {
  state = {
    columnToSort: "date",
    sortDirection: "desc"
  };
  handleSort = columnName => {
    this.setState(state => ({
      columnToSort: columnName,
      sortDirection:
        state.columnToSort === columnName
          ? invertDirection(state.sortDirection)
          : "asc"
    }));
  };
  render() {
    const { users, perPage, firestoreQuery, permission } = this.props;
    const orderedUsers = _.orderBy(
      users,
      [this.state.columnToSort],
      [this.state.sortDirection]
    );
    return (
      <div>
        {!isLoaded(users) ? (
          <LoadingBlock />
        ) : isEmpty(users) ? (
          <h2>No Users Found</h2>
        ) : (
          <div>
            {users.length > 1 ? (
              <div>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <TableSortLabel
                            direction={this.state.sortDirection}
                            onClick={() => this.handleSort("name")}
                          >
                            Name
                          </TableSortLabel>
                        </TableCell>
                        <TableCell>
                          <TableSortLabel
                            direction={this.state.sortDirection}
                            onClick={() => this.handleSort("email")}
                          >
                            Email
                          </TableSortLabel>
                        </TableCell>
                        <TableCell>
                          <TableSortLabel
                            direction={this.state.sortDirection}
                            onClick={() => this.handleSort("permission")}
                          >
                            Permission
                          </TableSortLabel>
                        </TableCell>
                        <TableCell>
                          <TableSortLabel
                            direction={this.state.sortDirection}
                            onClick={() => this.handleSort("region")}
                          >
                            Region
                          </TableSortLabel>
                        </TableCell>
                        <TableCell>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {orderedUsers.map((user, index) => (
                        <UsersTableRow
                          singleRow={user}
                          key={user.id}
                          rowId={user.id}
                          collection="users"
                        />
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                {users.length % 50 === 0 && (
                  <Button
                    className="show-more"
                    variant="contained"
                    color="primary"
                    onClick={this.handleShowMore}
                  >
                    Show Next 50 Users
                  </Button>
                )}
              </div>
            ) : (
              <LoadingBlock />
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    users: state.firestore.ordered.users
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect(props => {
    return [
      {
        collection: "users",
        where: [["deleted", "==", false]],
        orderBy: ["name", "asc"]
      }
    ];
  })
)(UsersTable);
