import React, { Component } from "react";

class ServiceTitleBar extends Component {
  render() {
    return (
      <div className="title-bar">
        <ul>
          <li>
            <a href="/sales">Sales</a>
          </li>
          <li>
            <a href="/service" className="active">
              Service
            </a>
          </li>
        </ul>
      </div>
    );
  }
}

export default ServiceTitleBar;
