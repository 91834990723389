export const createServicePart = data => {
  return (dispatch, getState, getFirebase) => {
    const firestore = getFirebase().firestore();
    firestore
      .collection("partpricing")
      .add({
        name: data.name,
        fileURL: data.fileURL
      })
      .then(() => {
        dispatch({
          type: "CREATE_SERVICE_PART",
          serviceParts: data
        });
      });
  };
};
