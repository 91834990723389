import React, { Component } from "react";
import { connect } from "react-redux";
import { reformatTimestamp } from "../../../../util/functions";
import firebase from "@firebase/app";
import "@firebase/storage";
import FileUploader from "react-firebase-file-uploader";

// Actions
import { updateVideo } from "../../../../store/actions/videoActions";

// MUI Stuff
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import TextField from "@material-ui/core/TextField";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class ServiceVideoDialog extends Component {
  state = {
    id: this.props.rowId,
    title: this.props.singleRow.title,
    order: this.props.singleRow.order,
    video: this.props.singleRow.video,
    isUploading: false,
    progress: 0
  };
  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };

  handleUploadStart = () => this.setState({ isUploading: true, progress: 0 });
  handleProgress = progress => this.setState({ progress });
  handleUploadError = error => {
    this.setState({ isUploading: false });
    // Handle upload error
    console.error(error);
  };
  handleUploadSuccess = async filename => {
    const downloadURL = await firebase
      .storage()
      .ref("serviceinventory")
      .child(filename)
      .getDownloadURL();
    this.setState(oldState => ({
      video: [downloadURL],
      uploadProgress: 100,
      isUploading: false
    }));
  };

  handleSave = () => {
    this.props.updateVideo(this.state);
    this.props.handleClose();
  };
  handleClose = () => {
    this.setState({
      status: this.props.singleRow.status
    });
    this.props.handleClose();
  };
  render() {
    const { singleRow, rowId } = this.props;
    let progressBarStyle = {
      width: this.state.progress + "%"
    };
    return (
      <Dialog
        fullScreen
        open={this.props.open}
        onClose={this.props.handleClose}
        TransitionComponent={Transition}
        className="dialog--edit"
      >
        <AppBar className="modal-appbar">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={this.handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6">{singleRow.title}</Typography>
            <Button
              autoFocus
              color="inherit"
              onClick={this.handleSave}
              disabled={this.state.isUploading}
            >
              Save
            </Button>
          </Toolbar>
        </AppBar>
        <div className="update">
          <div className="update--column">
            <h2>Video Information</h2>
            <h5>Title</h5>
            <div className="field" style={{ marginBottom: "30px" }}>
              <TextField
                id="title"
                label="Title"
                type="text"
                variant="filled"
                value={this.state.title}
                required
                onChange={this.handleChange}
              />
            </div>
            <h5>Date</h5>
            <p>{reformatTimestamp(singleRow.date.seconds)}</p>
            <h5>Order</h5>
            <div className="field" style={{ marginBottom: "30px" }}>
              <TextField
                id="order"
                label="Video Order"
                type="number"
                variant="filled"
                value={this.state.order}
                required
                onChange={this.handleChange}
              />
            </div>
          </div>
          <div className="update--column">
            <h5>Video</h5>
            <div className="field" style={{ marginBottom: "30px" }}>
              <div className="upload">
                {this.state.video[0] && !this.state.isUploading && (
                  <div className="video">
                    <video controls>
                      <source src={this.state.video[0]} type="video/mp4" />
                    </video>
                  </div>
                )}
                {this.state.isUploading && (
                  <div className="upload-progress">
                    <h5>Uploading</h5>
                    <div
                      className="upload-progress--background"
                      style={{ background: "#eeeeee" }}
                    >
                      <div
                        className="upload-progress--bar"
                        style={progressBarStyle}
                      ></div>
                    </div>
                  </div>
                )}
                <label className="file-upload">
                  Replace Video
                  <FileUploader
                    name="video"
                    randomizeFilename
                    storageRef={firebase.storage().ref("serviceinventory")}
                    onUploadStart={this.handleUploadStart}
                    onUploadError={this.handleUploadError}
                    onUploadSuccess={this.handleUploadSuccess}
                    onProgress={this.handleProgress}
                    multiple
                  />
                </label>
              </div>
              <p style={{ marginTop: "10px" }}>
                <strong>Videos should be uploaded in mp4 format</strong>
              </p>
            </div>
          </div>
        </div>
      </Dialog>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateVideo: data => dispatch(updateVideo(data))
  };
};

export default connect(null, mapDispatchToProps)(ServiceVideoDialog);
