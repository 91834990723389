const initState = {};

const sraReducer = (state = initState, action) => {
  switch (action.type) {
    case "CREATE_SRA":
      console.log("SRA Request Created");
      return state;
    case "CREATE_SRA_ERROR":
      console.log("Error Creating SRA");
      return state;
    case "UPDATE_SRA":
      console.log("SRA Request Updated");
      return state;
    case "UPDATE_SRA_ERROR":
      console.log("Error Updating SRA");
      return state;
    case "ADD_USER_DATA_SRA":
      console.log("User Info Added");
      return state;
    case "ADD_USER_DATA_SRA_ERROR":
      console.log("Error Adding User Info");
      return state;
    default:
      return state;
  }
};

export default sraReducer;
