import React, { Component } from "react";
import { connect } from "react-redux";
import { reformatTimestamp } from "../../../../util/functions";
import { withRouter } from "react-router-dom";

// Actions
import { updateSra } from "../../../../store/actions/sraActions";

// Helpers
import { formatUSD } from "../../../../util/functions";

// MUI Stuff
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class ServiceSraSingleEdit extends Component {
  state = {
    id: this.props.rowId,
    status: this.props.singleRow.status,
    info: ""
  };

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };

  handleSelectChange = name => e => {
    this.setState({
      [name]: e.target.value
    });
  };

  handleSave = () => {
    this.props.updateSra(this.state, this.props.collection);
    this.props.history.push("/service/sra/active");
  };

  render() {
    const { singleRow, rowId, permission, history } = this.props;

    let hasCost = false;
    if (
      singleRow.hasOwnProperty("fieldTechTravelCost") ||
      singleRow.hasOwnProperty("fieldTechLaborCost") ||
      singleRow.hasOwnProperty("shippingCost")
    ) {
      hasCost = true;
    }

    return (
      <div className="dialog--single">
        <div className="update">
          <div className="update--column">
            <h2>Order Information</h2>
            {permission !== "Service Rep" ? (
              <div className="status">
                <FormControl variant="filled">
                  <InputLabel id="status-label">Status</InputLabel>
                  <Select
                    id="status"
                    labelId="status-label"
                    variant="filled"
                    label="Status"
                    value={this.state.status}
                    onChange={this.handleSelectChange("status")}
                  >
                    <MenuItem value="Requested">Requested</MenuItem>
                    <MenuItem value="Denied">Denied</MenuItem>
                    <MenuItem value="Approved">Approved</MenuItem>
                    <MenuItem value="Active">Active</MenuItem>
                    <MenuItem value="Cancelled">Cancelled</MenuItem>
                    <MenuItem value="Closed">Closed</MenuItem>
                  </Select>
                </FormControl>
              </div>
            ) : (
              <div>
                <h5>Status</h5>
                <p>{singleRow.status}</p>
              </div>
            )}
            <h5>Date</h5>
            <p>{reformatTimestamp(singleRow.date.seconds)}</p>
            <h5>Ordered By</h5>
            <p>{singleRow.userName}</p>
            <h5>Region</h5>
            <p>{singleRow.userRegion}</p>
            <h5>Make</h5>
            <p>{singleRow.make}</p>
            <h5>Model</h5>
            <p>{singleRow.model}</p>
            <h5>Problem</h5>
            <p>{singleRow.problem}</p>
            <h5>Comments</h5>
            <p>{singleRow.comments}</p>
          </div>
          <div className="update--column">
            <h2>Customer Information</h2>
            <h5>Facility Name</h5>
            <p>{singleRow.facilityName}</p>
            {singleRow.customerId && (
              <div>
                <h5>Customer ID</h5>
                <p>{singleRow.customerId}</p>
              </div>
            )}
            <h5>Priority</h5>
            <p>{singleRow.priority}</p>
            <h5>Turnaround</h5>
            <p>{singleRow.turnaround}</p>
            {singleRow.dateReceived && (
              <div>
                <h5>Date Received</h5>
                <p>{reformatTimestamp(singleRow.dateReceived.seconds)}</p>
              </div>
            )}
            {singleRow.biomedContactName && (
              <>
                <h5>Biomed Contact Name</h5>
                <p>{singleRow.biomedContactName}</p>
              </>
            )}
            {singleRow.biomedContactPhone && (
              <>
                <h5>Biomed Contact Phone</h5>
                <p>{singleRow.biomedContactPhone}</p>
              </>
            )}
            {singleRow.biomedContactEmail && (
              <>
                <h5>Biomed Contact Email</h5>
                <p>{singleRow.biomedContactEmail}</p>
              </>
            )}
          </div>
          {hasCost && (
            <div className="update--column">
              <h2>Order Costs</h2>
              <h5>Field Tech Travel Cost</h5>
              <p>{formatUSD(singleRow.fieldTechTravelCost)}</p>
              <h5>Field Tech Labor Cost</h5>
              <p>{formatUSD(singleRow.fieldTechLaborCost)}</p>
              <h5>Shipping Cost</h5>
              <p>{formatUSD(singleRow.shippingCost)}</p>
            </div>
          )}
        </div>
        <Button variant="contained" color="primary" onClick={this.handleSave}>
          Update Request
        </Button>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateSra: (data, collection) => dispatch(updateSra(data, collection))
  };
};

export default withRouter(
  connect(null, mapDispatchToProps)(ServiceSraSingleEdit)
);
