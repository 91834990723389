const initState = {};

const warrantyReducer = (state = initState, action) => {
  switch (action.type) {
    case "CREATE_WARRANTY_REQUEST":
      console.log("Warranty Request Created");
      return state;
    case "CREATE_WARRANTY_REQUEST_ERROR":
      console.log("Error Creating Warranty Request");
      return state;
    case "UPDATE_WARRANTY":
      console.log("Warranty Request Updated");
      return state;
    case "UPDATE_WARRANTY_ERROR":
      console.log("Error Updating Warranty Request");
      return state;
    default:
      return state;
  }
};

export default warrantyReducer;
