const initState = {};

const quoteReducer = (state = initState, action) => {
  switch (action.type) {
    case "CREATE_QUOTE":
      console.log("Quote Data Added");
      return state;
    case "CREATE_QUOTE_ERROR":
      console.log("Error Writing Quote Data");
      return state;
    case "UPDATE_QUOTE":
      console.log("Quote Data Updated");
      return state;
    case "UPDATE_QUOTE_ERROR":
      console.log("Error Updating Quote Data");
      return state;
    case "MODEL_SELECT_SUCCESS":
      console.log("Models Loaded");
      return {
        ...state,
        models: action.models
      };
    case "MODEL_SELECT_ERROR":
      console.log("Error Loading Models");
      return state;
    case "ADD_USER_DATA_QUOTE":
      console.log("User Info Added");
      return state;
    case "ADD_USER_DATA_QUOTE_ERROR":
      console.log("Error Adding User Info");
      return state;
    default:
      return state;
  }
};

export default quoteReducer;
