import "date-fns";
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";

// Actions
import { createQuote } from "../../../store/actions/quoteActions";

// Components
import ServiceTitleBar from "../../global/ServiceTitleBar";
import ServiceQuoteTabs from "./components/ServiceQuoteTabs";

// Material UI
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";

// Images
import AddIcon from "../../../img/icon-circle-plus.svg";
import RemoveIcon from "../../../img/icon-circle-x.svg";

class serviceQuoteRequest extends Component {
  state = {
    serviceFacilityName: "",
    serviceFacilityAddress: "",
    serviceFacilityAddressCity: "",
    serviceFacilityAddressState: "",
    serviceFacilityAddressZip: "",
    billingSame: true,
    billFacilityName: "",
    billFacilityAddress: "",
    billFacilityAddressCity: "",
    billFacilityAddressState: "",
    billFacilityAddressZip: "",
    facilityContactName: "",
    facilityContactPhone: "",
    facilityContactEmail: "",
    facilityContactTitle: "",
    closeDateRaw: new Date(),
    customerType: "",
    customerStatus: "",
    serviceTermStartDateRaw: new Date(),
    serviceTermEndDateRaw: new Date(),
    serviceTerm: "",
    managedBy: "",
    contractType: "",
    status: "",
    notes: "",
    purchaseOrder: "",
    coverageBronze: false,
    coverageSilver: false,
    coverageGold: false,
    coveragePlatinum: false,
    microscopes: [
      {
        make: "",
        model: "",
        silver: 0,
        gold: 0,
        platinum: 0,
        serial: "",
        docId: ""
      }
    ],
    additionalContacts: "",
    quotedFacility: "",
    quotedFacilityWhyNo: "",
    existingService: "",
    penteroKinevo: "",
    penteroKinevoQuoted: "",
    notes_build: "",
    formError: ""
  };

  // Comment/uncomment this for easier testing
  // componentDidMount() {
  //   this.setState({
  //     serviceFacilityName: "Facility Name",
  //     serviceFacilityAddress: "1234 Facility St",
  //     serviceFacilityAddressCity: "Denver",
  //     serviceFacilityAddressState: "CO",
  //     serviceFacilityAddressZip: "80209",
  //     billingSame: true,
  //     billFacilityName: "",
  //     billFacilityAddress: "",
  //     billFacilityAddressCity: "",
  //     billFacilityAddressState: "",
  //     billFacilityAddressZip: "",
  //     facilityContactName: "Fist Lastname",
  //     facilityContactPhone: "123-456-7890",
  //     facilityContactEmail: "first@facility.com",
  //     facilityContactTitle: "Test Title",
  //     closeDateRaw: new Date(),
  //     customerType: "Hospital",
  //     customerStatus: "New",
  //     serviceTermStartDateRaw: new Date(),
  //     serviceTermEndDateRaw: new Date(),
  //     serviceTerm: "1",
  //     managedBy: "New - Rep",
  //     contractType: "New",
  //     status: "",
  //     notes: "Test notes",
  //     purchaseOrder: "1234567890",
  //     coverageBronze: false,
  //     coverageSilver: true,
  //     coverageGold: true,
  //     coveragePlatinum: true,
  //     microscopes: [
  //       {
  //         make: "",
  //         model: "",
  //         silver: 0,
  //         gold: 0,
  //         platinum: 0,
  //         serial: "",
  //         docId: ""
  //       }
  //     ],
  //     additionalContacts: "",
  //     quotedFacility: "",
  //     quotedFacilityWhyNo: "",
  //     existingService: "Yes",
  //     penteroKinevo: "No",
  //     penteroKinevoQuoted: "",
  //     notes_build: "",
  //     formError: ""
  //   });
  // }

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };

  handleSelectChange = name => e => {
    this.setState({
      [name]: e.target.value
    });
  };

  handleCheckboxChange = e => {
    this.setState({
      [e.target.id]: e.target.checked
    });
  };

  handleCloseDateChange = date => {
    this.setState({
      closeDateRaw: date
    });
  };

  handleTermStartDateChange = date => {
    this.setState({
      serviceTermStartDateRaw: date
    });
  };

  handleMicroscopeMakeChange = idx => evt => {
    const newMicroscopes = this.state.microscopes.map((microscope, sidx) => {
      if (idx !== sidx) return microscope;
      return { ...microscope, make: evt.target.value };
    });
    this.setState({ microscopes: newMicroscopes });
  };

  handleMicroscopeModelChange = idx => evt => {
    const newMicroscopes = this.state.microscopes.map((microscope, sidx) => {
      if (idx !== sidx) return microscope;
      const { id } = evt.currentTarget.dataset;
      return {
        ...microscope,
        model: evt.target.value,
        docId: id
      };
    });
    this.setState({ microscopes: newMicroscopes });
  };

  handleMicroscopeSerialChange = idx => evt => {
    const newMicroscopes = this.state.microscopes.map((microscope, sidx) => {
      if (idx !== sidx) return microscope;
      return { ...microscope, serial: evt.target.value };
    });
    this.setState({ microscopes: newMicroscopes });
  };

  handleRemoveMicroscope = idx => () => {
    this.setState({
      microscopes: this.state.microscopes.filter((s, sidx) => idx !== sidx)
    });
  };

  handleAddMicroscope = () => {
    this.setState({
      microscopes: this.state.microscopes.concat([
        {
          make: "",
          model: "",
          silver: 0,
          gold: 0,
          platinum: 0,
          serial: "",
          docId: ""
        }
      ])
    });
  };

  handleSubmit = e => {
    e.preventDefault();

    if (
      this.state.coverageBronze === false &&
      this.state.coverageSilver === false &&
      this.state.coverageGold === false &&
      this.state.coveragePlatinum === false
    ) {
      this.setState({
        formError: "You must select at least one coverage level."
      });
    }

    this.setState({
      formError: ""
    });

    const coverageMap = {
      coverageBronze: "bronze",
      coverageSilver: "silver",
      coverageGold: "gold",
      coveragePlatinum: "platinum"
    };

    let dataToSave = { ...this.state };

    let prices = this.props.pricingus;
    if (this.props.profile.region === "United Kingdom") {
      prices = this.props.pricinguk;
    }

    let selectedCoverages = [];
    Object.keys(coverageMap).forEach(level => {
      if (dataToSave[level]) {
        selectedCoverages.push(level);
      }
    });

    let priceError = false;

    dataToSave.microscopes.forEach(row => {
      // Bail is an error has already been thrown
      if (priceError) {
        return;
      }

      // Find pricing for matching equipment
      let equipmentCost = 0;
      let match = null;
      let matches = prices.filter(m => {
        return m.manufacturer === row.manufacturer && m.model === row.model;
      });
      if (matches.length > 0) {
        match = matches[0];
      }

      // Bail if all pricing is missing
      if (equipmentCost === 0 && !match) {
        priceError = true;
        this.setState({
          formError: row.manufacturer + " " + row.model + " is missing pricing."
        });
      }

      // Get pricing for each coverage level selected
      selectedCoverages.forEach(level => {
        // Get the per-service cost for the unit
        let cost = match[coverageMap[level]];

        // Bail if a cost isn't available
        if (cost === 0) {
          priceError = true;
          this.setState({
            formError:
              row.manufacturer +
              " " +
              row.model +
              " is missing pricing for " +
              " " +
              coverageMap[level] +
              "coverage."
          });
        }

        row[coverageMap[level]] = Number(cost);
      });
    });

    if (this.state.formError !== "") {
      return;
    }

    this.props.createQuote(this.state);
    this.props.history.push("/service/quote/open");
  };

  render() {
    const { auth, pricingus, pricinguk, profile } = this.props;

    let quoteprices = pricingus;
    if (profile.region === "United Kingdom") {
      quoteprices = pricinguk;
    }

    // Function to create makes dropdown
    const renderMakeList = () => {
      let makeList = [...new Set(quoteprices.map(scope => scope.make))];
      return makeList.map((make, i) => {
        return (
          <MenuItem key={i} value={make}>
            {make}
          </MenuItem>
        );
      });
    };

    // Function to create models dropdown
    const renderModelList = makeName => {
      const modelList = quoteprices.filter(({ make }) => make === makeName);
      const modelListSorted = modelList.sort((a, b) =>
        a.model > b.model ? 1 : -1
      );
      return modelListSorted.map((model, i) => {
        return (
          <MenuItem key={i} value={model.model} data-id={model.id}>
            {model.model}
          </MenuItem>
        );
      });
    };

    if (!auth.uid) return <Redirect to="/login" />;

    return (
      <div>
        <ServiceTitleBar />
        <ServiceQuoteTabs />
        <h1>Build Service Contract Quote</h1>
        {profile && (
          <form onSubmit={this.handleSubmit}>
            <div className="field">
              <TextField
                id="serviceFacilityName"
                label="Service Facility Name"
                type="text"
                variant="filled"
                required
                value={this.state.serviceFacilityName}
                onChange={this.handleChange}
              />
            </div>
            <div className="field">
              <TextField
                id="serviceFacilityAddress"
                label="Service Address"
                type="text"
                variant="filled"
                required
                value={this.state.serviceFacilityAddress}
                onChange={this.handleChange}
              />
            </div>
            <div className="field half">
              <TextField
                id="serviceFacilityAddressCity"
                label="City"
                type="text"
                variant="filled"
                required
                value={this.state.serviceFacilityAddressCity}
                onChange={this.handleChange}
              />
            </div>
            {profile.region !== "United Kingdom" && (
              <div className="field quarter">
                <FormControl variant="filled">
                  <InputLabel id="serviceFacilityAddressState-label">
                    State *
                  </InputLabel>
                  <Select
                    id="serviceFacilityAddressState"
                    labelId="serviceFacilityAddressState-label"
                    variant="filled"
                    label="State"
                    required
                    value={this.state.serviceFacilityAddressState}
                    onChange={this.handleSelectChange(
                      "serviceFacilityAddressState"
                    )}
                  >
                    <MenuItem value="AL">Alabama</MenuItem>
                    <MenuItem value="AK">Alaska</MenuItem>
                    <MenuItem value="AZ">Arizona</MenuItem>
                    <MenuItem value="AR">Arkansas</MenuItem>
                    <MenuItem value="CA">California</MenuItem>
                    <MenuItem value="CO">Colorado</MenuItem>
                    <MenuItem value="CT">Connecticut</MenuItem>
                    <MenuItem value="DE">Delaware</MenuItem>
                    <MenuItem value="DC">District Of Columbia</MenuItem>
                    <MenuItem value="FL">Florida</MenuItem>
                    <MenuItem value="GA">Georgia</MenuItem>
                    <MenuItem value="HI">Hawaii</MenuItem>
                    <MenuItem value="ID">Idaho</MenuItem>
                    <MenuItem value="IL">Illinois</MenuItem>
                    <MenuItem value="IN">Indiana</MenuItem>
                    <MenuItem value="IA">Iowa</MenuItem>
                    <MenuItem value="KS">Kansas</MenuItem>
                    <MenuItem value="KY">Kentucky</MenuItem>
                    <MenuItem value="LA">Louisiana</MenuItem>
                    <MenuItem value="ME">Maine</MenuItem>
                    <MenuItem value="MD">Maryland</MenuItem>
                    <MenuItem value="MA">Massachusetts</MenuItem>
                    <MenuItem value="MI">Michigan</MenuItem>
                    <MenuItem value="MN">Minnesota</MenuItem>
                    <MenuItem value="MS">Mississippi</MenuItem>
                    <MenuItem value="MO">Missouri</MenuItem>
                    <MenuItem value="MT">Montana</MenuItem>
                    <MenuItem value="NE">Nebraska</MenuItem>
                    <MenuItem value="NV">Nevada</MenuItem>
                    <MenuItem value="NH">New Hampshire</MenuItem>
                    <MenuItem value="NJ">New Jersey</MenuItem>
                    <MenuItem value="NM">New Mexico</MenuItem>
                    <MenuItem value="NY">New York</MenuItem>
                    <MenuItem value="NC">North Carolina</MenuItem>
                    <MenuItem value="ND">North Dakota</MenuItem>
                    <MenuItem value="OH">Ohio</MenuItem>
                    <MenuItem value="OK">Oklahoma</MenuItem>
                    <MenuItem value="OR">Oregon</MenuItem>
                    <MenuItem value="PA">Pennsylvania</MenuItem>
                    <MenuItem value="RI">Rhode Island</MenuItem>
                    <MenuItem value="SC">South Carolina</MenuItem>
                    <MenuItem value="SD">South Dakota</MenuItem>
                    <MenuItem value="TN">Tennessee</MenuItem>
                    <MenuItem value="TX">Texas</MenuItem>
                    <MenuItem value="UT">Utah</MenuItem>
                    <MenuItem value="VT">Vermont</MenuItem>
                    <MenuItem value="VA">Virginia</MenuItem>
                    <MenuItem value="WA">Washington</MenuItem>
                    <MenuItem value="WV">West Virginia</MenuItem>
                    <MenuItem value="WI">Wisconsin</MenuItem>
                    <MenuItem value="WY">Wyoming</MenuItem>
                  </Select>
                </FormControl>
              </div>
            )}
            {profile.region === "United Kingdom" ? (
              <div className="field quarter">
                <TextField
                  id="serviceFacilityAddressZip"
                  label="Postcode"
                  type="text"
                  variant="filled"
                  required
                  value={this.state.serviceFacilityAddressZip}
                  onChange={this.handleChange}
                />
              </div>
            ) : (
              <div className="field quarter">
                <TextField
                  id="serviceFacilityAddressZip"
                  label="Zip"
                  type="text"
                  variant="filled"
                  required
                  value={this.state.serviceFacilityAddressZip}
                  onChange={this.handleChange}
                />
              </div>
            )}
            <div className="field">
              <h2>Billing Information:</h2>
              <label>
                <Checkbox
                  id="billingSame"
                  label="Same as Service Info"
                  checked={this.state.billingSame}
                  color="primary"
                  onChange={this.handleCheckboxChange}
                />
                <span className="checkbox-label">Same as Service Info</span>
              </label>
              {this.state.billingSame === false && (
                <div className="billing-toggle">
                  <div className="field">
                    <TextField
                      id="billFacilityName"
                      label="Billing Facility Name"
                      type="text"
                      variant="filled"
                      required
                      value={this.state.billFacilityName}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="field">
                    <TextField
                      id="billFacilityAddress"
                      label="Billing Address"
                      type="text"
                      variant="filled"
                      required
                      value={this.state.billFacilityAddress}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="field half">
                    <TextField
                      id="billFacilityAddressCity"
                      label="City"
                      type="text"
                      variant="filled"
                      required
                      value={this.state.billFacilityAddressCity}
                      onChange={this.handleChange}
                    />
                  </div>
                  {profile.region !== "United Kingdom" && (
                    <div className="field quarter">
                      <FormControl variant="filled">
                        <InputLabel id="billFacilityAddressState-label">
                          State *
                        </InputLabel>
                        <Select
                          id="billFacilityAddressState"
                          labelId="billFacilityAddressState-label"
                          variant="filled"
                          label="State"
                          required
                          value={this.state.billFacilityAddressState}
                          onChange={this.handleSelectChange(
                            "billFacilityAddressState"
                          )}
                        >
                          <MenuItem value="AL">Alabama</MenuItem>
                          <MenuItem value="AK">Alaska</MenuItem>
                          <MenuItem value="AZ">Arizona</MenuItem>
                          <MenuItem value="AR">Arkansas</MenuItem>
                          <MenuItem value="CA">California</MenuItem>
                          <MenuItem value="CO">Colorado</MenuItem>
                          <MenuItem value="CT">Connecticut</MenuItem>
                          <MenuItem value="DE">Delaware</MenuItem>
                          <MenuItem value="DC">District Of Columbia</MenuItem>
                          <MenuItem value="FL">Florida</MenuItem>
                          <MenuItem value="GA">Georgia</MenuItem>
                          <MenuItem value="HI">Hawaii</MenuItem>
                          <MenuItem value="ID">Idaho</MenuItem>
                          <MenuItem value="IL">Illinois</MenuItem>
                          <MenuItem value="IN">Indiana</MenuItem>
                          <MenuItem value="IA">Iowa</MenuItem>
                          <MenuItem value="KS">Kansas</MenuItem>
                          <MenuItem value="KY">Kentucky</MenuItem>
                          <MenuItem value="LA">Louisiana</MenuItem>
                          <MenuItem value="ME">Maine</MenuItem>
                          <MenuItem value="MD">Maryland</MenuItem>
                          <MenuItem value="MA">Massachusetts</MenuItem>
                          <MenuItem value="MI">Michigan</MenuItem>
                          <MenuItem value="MN">Minnesota</MenuItem>
                          <MenuItem value="MS">Mississippi</MenuItem>
                          <MenuItem value="MO">Missouri</MenuItem>
                          <MenuItem value="MT">Montana</MenuItem>
                          <MenuItem value="NE">Nebraska</MenuItem>
                          <MenuItem value="NV">Nevada</MenuItem>
                          <MenuItem value="NH">New Hampshire</MenuItem>
                          <MenuItem value="NJ">New Jersey</MenuItem>
                          <MenuItem value="NM">New Mexico</MenuItem>
                          <MenuItem value="NY">New York</MenuItem>
                          <MenuItem value="NC">North Carolina</MenuItem>
                          <MenuItem value="ND">North Dakota</MenuItem>
                          <MenuItem value="OH">Ohio</MenuItem>
                          <MenuItem value="OK">Oklahoma</MenuItem>
                          <MenuItem value="OR">Oregon</MenuItem>
                          <MenuItem value="PA">Pennsylvania</MenuItem>
                          <MenuItem value="RI">Rhode Island</MenuItem>
                          <MenuItem value="SC">South Carolina</MenuItem>
                          <MenuItem value="SD">South Dakota</MenuItem>
                          <MenuItem value="TN">Tennessee</MenuItem>
                          <MenuItem value="TX">Texas</MenuItem>
                          <MenuItem value="UT">Utah</MenuItem>
                          <MenuItem value="VT">Vermont</MenuItem>
                          <MenuItem value="VA">Virginia</MenuItem>
                          <MenuItem value="WA">Washington</MenuItem>
                          <MenuItem value="WV">West Virginia</MenuItem>
                          <MenuItem value="WI">Wisconsin</MenuItem>
                          <MenuItem value="WY">Wyoming</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  )}
                  {profile.region === "United Kingdom" ? (
                    <div className="field quarter">
                      <TextField
                        id="billFacilityAddressZip"
                        label="Postcode"
                        type="text"
                        variant="filled"
                        required
                        value={this.state.billFacilityAddressZip}
                        onChange={this.handleChange}
                      />
                    </div>
                  ) : (
                    <div className="field quarter">
                      <TextField
                        id="billFacilityAddressZip"
                        label="Zip"
                        type="text"
                        variant="filled"
                        required
                        value={this.state.billFacilityAddressZip}
                        onChange={this.handleChange}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="field">
              <h2>Facility Contact:</h2>
            </div>
            <div className="field half">
              <TextField
                id="facilityContactName"
                label="Facility Contact Name"
                type="text"
                variant="filled"
                required
                value={this.state.facilityContactName}
                onChange={this.handleChange}
              />
            </div>

            <div className="field half">
              <TextField
                id="facilityContactPhone"
                label="Facility Contact Phone"
                type="phone"
                variant="filled"
                required
                value={this.state.facilityContactPhone}
                onChange={this.handleChange}
              />
            </div>

            <div className="field half">
              <TextField
                id="facilityContactEmail"
                label="Facility Contact Email"
                type="email"
                variant="filled"
                required
                value={this.state.facilityContactEmail}
                onChange={this.handleChange}
              />
            </div>

            <div className="field half">
              <TextField
                id="facilityContactTitle"
                label="Facility Contact Title"
                type="text"
                variant="filled"
                required
                value={this.state.facilityContactTitle}
                onChange={this.handleChange}
              />
            </div>

            <div className="field">
              <h2>Customer Information:</h2>
            </div>
            <div className="field quarter">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="closeDate"
                  label="Expected Close Date *"
                  value={this.state.closeDateRaw}
                  onChange={this.handleCloseDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
            <div className="field quarter">
              <FormControl variant="filled">
                <InputLabel id="customerType-label">Customer Type *</InputLabel>
                <Select
                  id="customerType"
                  labelId="customerType-label"
                  variant="filled"
                  label="Customer Type"
                  required
                  value={this.state.customerType}
                  onChange={this.handleSelectChange("customerType")}
                >
                  <MenuItem value="Hospital">Hospital</MenuItem>
                  <MenuItem value="Surgery Center">Surgery Center</MenuItem>
                  <MenuItem value="Clinic">Clinic</MenuItem>
                  <MenuItem value="Other">Other</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="field quarter">
              <FormControl variant="filled">
                <InputLabel id="customerStatus-label">
                  Customer Status *
                </InputLabel>
                <Select
                  id="customerStatus"
                  labelId="customerStatus-label"
                  variant="filled"
                  label="Customer Status"
                  required
                  value={this.state.customerStatus}
                  onChange={this.handleSelectChange("customerStatus")}
                >
                  <MenuItem value="New">New</MenuItem>
                  <MenuItem value="Existing">Existing</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="field quarter">
              <TextField
                id="purchaseOrder"
                label="Purchase Order #"
                type="text"
                variant="filled"
                value={this.state.purchaseOrder}
                onChange={this.handleChange}
              />
            </div>
            <div className="field">
              <h2>Contract Terms:</h2>
            </div>
            <div className="field quarter">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="serviceTermStartDateRaw"
                  label="Term Start Date *"
                  value={this.state.serviceTermStartDateRaw}
                  onChange={this.handleTermStartDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
            <div className="field quarter">
              <TextField
                id="serviceTerm"
                label="Contract Length (Years)"
                type="number"
                variant="filled"
                required
                value={this.state.serviceTerm}
                onChange={this.handleChange}
              />
            </div>
            <div className="field quarter">
              <FormControl variant="filled">
                <InputLabel id="managedBy-label">Managed By *</InputLabel>
                <Select
                  id="managedBy"
                  labelId="managedBy-label"
                  variant="filled"
                  label="Managed By"
                  required
                  value={this.state.managedBy}
                  onChange={this.handleSelectChange("managedBy")}
                >
                  <MenuItem value="New - Rep">New - Rep</MenuItem>
                  <MenuItem value="New - Dept">New - Dept</MenuItem>
                  <MenuItem value="Renewal - Rep">Renewal - Rep</MenuItem>
                  <MenuItem value="Renewal - Dept">Renewal - Dept</MenuItem>
                </Select>
              </FormControl>
            </div>

            <div className="field quarter">
              <FormControl variant="filled">
                <InputLabel id="managedBy-label">Contract Type *</InputLabel>
                <Select
                  id="contractType"
                  labelId="contractType-label"
                  variant="filled"
                  label="Contract Type"
                  required
                  value={this.state.contractType}
                  onChange={this.handleSelectChange("contractType")}
                >
                  <MenuItem value="New">New</MenuItem>
                  <MenuItem value="Renewal">Renewal</MenuItem>
                </Select>
              </FormControl>
            </div>

            <div className="field half coverage-levels">
              <h6>Coverage Levels to Quote:</h6>
              {profile.region === "United Kingdom" && (
                <label>
                  <Checkbox
                    id="coverageBronze"
                    label="Bronze"
                    checked={this.state.coverageBronze}
                    color="primary"
                    onChange={this.handleCheckboxChange}
                  />
                  <span className="checkbox-label">Bronze</span>
                </label>
              )}
              <label>
                <Checkbox
                  id="coverageSilver"
                  label="Silver"
                  checked={this.state.coverageSilver}
                  color="primary"
                  onChange={this.handleCheckboxChange}
                />
                <span className="checkbox-label">Silver</span>
              </label>
              <label>
                <Checkbox
                  id="coverageGold"
                  label="Gold"
                  checked={this.state.coverageGold}
                  color="primary"
                  onChange={this.handleCheckboxChange}
                />
                <span className="checkbox-label">Gold</span>
              </label>
              <label>
                <Checkbox
                  id="coveragePlatinum"
                  label="Platinum"
                  checked={this.state.coveragePlatinum}
                  color="primary"
                  onChange={this.handleCheckboxChange}
                />
                <span className="checkbox-label">Platinum</span>
              </label>
            </div>
            <div className="field">
              <h2>Microscopes:</h2>
            </div>
            {quoteprices && (
              <div className="microscope">
                {this.state.microscopes.map((microscope, idx) => (
                  <div className="microscope-selects" key={idx}>
                    <div className="field quarter">
                      <FormControl variant="filled">
                        <InputLabel id="make-label">Make</InputLabel>
                        <Select
                          id="make"
                          labelId="make-label"
                          variant="filled"
                          label="make"
                          required
                          value={microscope.make}
                          onChange={this.handleMicroscopeMakeChange(idx)}
                        >
                          {renderMakeList()}
                        </Select>
                      </FormControl>
                    </div>
                    <div className="field quarter">
                      <FormControl variant="filled">
                        <InputLabel id="model-label">Model</InputLabel>
                        <Select
                          id="model"
                          labelId="model-label"
                          variant="filled"
                          label="model"
                          required
                          value={microscope.model}
                          onChange={this.handleMicroscopeModelChange(idx)}
                        >
                          {renderModelList(microscope.make)}
                        </Select>
                      </FormControl>
                    </div>
                    <div className="field quarter">
                      <TextField
                        id="serial"
                        label="Serial #"
                        type="text"
                        variant="filled"
                        onChange={this.handleMicroscopeSerialChange(idx)}
                      />
                    </div>
                    <div className="field quarter">
                      {idx > 0 && (
                        <Button
                          className="microscope-remove"
                          variant="contained"
                          color="primary"
                          onClick={this.handleRemoveMicroscope(idx)}
                        >
                          <img src={RemoveIcon} alt="icon" />
                        </Button>
                      )}
                      {idx === this.state.microscopes.length - 1 && (
                        <Button
                          className="microscope-add"
                          variant="contained"
                          color="primary"
                          onClick={this.handleAddMicroscope}
                        >
                          <img src={AddIcon} alt="icon" />
                        </Button>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            )}

            <div className="field">
              <h2>Questions:</h2>
            </div>

            <div className="field">
              <TextField
                id="additionalContacts"
                label="Any additional Contacts for this facility?"
                type="text"
                variant="filled"
                multiline={true}
                rows={5}
                rowsMax={5}
                value={this.state.additionalContacts}
                onChange={this.handleChange}
              />
            </div>

            <div className="field"></div>

            <div className="field quarter">
              <FormControl variant="filled">
                <InputLabel id="managedBy-label">
                  Were all microscopes quoted at the facility? *
                </InputLabel>
                <Select
                  id="quotedFacility"
                  labelId="quotedFacility"
                  variant="filled"
                  label="Were all microscopes quoted at the facility?"
                  required
                  value={this.state.quotedFacility}
                  onChange={this.handleSelectChange("quotedFacility")}
                >
                  <MenuItem value="Yes">Yes</MenuItem>
                  <MenuItem value="No">No</MenuItem>
                </Select>
              </FormControl>
            </div>

            {this.state.quotedFacility === "No" && (
              <div className="field half">
                <TextField
                  id="quotedFacilityWhyNo"
                  label="Why?"
                  type="text"
                  variant="filled"
                  value={this.state.quotedFacilityWhyNo}
                  onChange={this.handleChange}
                />
              </div>
            )}

            <div className="field"></div>

            <div className="field quarter">
              <FormControl variant="filled">
                <InputLabel id="managedBy-label">
                  Does facility have existing service contract with Prescott’s?
                  *
                </InputLabel>
                <Select
                  id="existingService"
                  labelId="existingService"
                  variant="filled"
                  label="Does facility have existing service contract with Prescott’s?"
                  required
                  value={this.state.existingService}
                  onChange={this.handleSelectChange("existingService")}
                >
                  <MenuItem value="Yes">Yes</MenuItem>
                  <MenuItem value="No">No</MenuItem>
                </Select>
              </FormControl>
            </div>

            <div className="field"></div>

            <div className="field quarter">
              <FormControl variant="filled">
                <InputLabel id="managedBy-label">
                  Does facility have an Pentero or Kinevo scopes? *
                </InputLabel>
                <Select
                  id="penteroKinevo"
                  labelId="penteroKinevo"
                  variant="filled"
                  label="Does facility have an Pentero or Kinevo scopes?"
                  required
                  value={this.state.penteroKinevo}
                  onChange={this.handleSelectChange("penteroKinevo")}
                >
                  <MenuItem value="Yes">Yes</MenuItem>
                  <MenuItem value="No">No</MenuItem>
                </Select>
              </FormControl>
            </div>

            {this.state.penteroKinevo === "Yes" && (
              <div className="field quarter">
                <FormControl variant="filled">
                  <InputLabel id="managedBy-label">
                    Have these been quoted? *
                  </InputLabel>
                  <Select
                    id="penteroKinevoQuoted"
                    labelId="penteroKinevoQuoted"
                    variant="filled"
                    label="Have these been quoted?"
                    value={this.state.penteroKinevoQuoted}
                    onChange={this.handleSelectChange("penteroKinevoQuoted")}
                  >
                    <MenuItem value="Yes">Yes</MenuItem>
                    <MenuItem value="No">No</MenuItem>
                  </Select>
                </FormControl>
              </div>
            )}

            <div className="field">
              <h2>Notes:</h2>
            </div>

            <div className="field">
              <TextField
                id="notes_build"
                label="Notes"
                variant="filled"
                multiline={true}
                rows={5}
                rowsMax={5}
                value={this.state.notes_build}
                onChange={this.handleChange}
              ></TextField>
            </div>

            <div className="field">
              {this.state.formError && (
                <p className="red">{this.state.formError}</p>
              )}
              <Button type="submit" variant="contained" color="primary">
                Submit Request
              </Button>
            </div>
          </form>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    pricingus: state.firestore.ordered.pricingus,
    pricinguk: state.firestore.ordered.pricinguk,
    auth: state.firebase.auth,
    profile: state.firebase.profile
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createQuote: data => dispatch(createQuote(data))
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect([
    {
      collection: "pricingus",
      orderBy: ["make", "asc"]
    },
    {
      collection: "pricinguk",
      orderBy: ["make", "asc"]
    }
  ])
)(serviceQuoteRequest);
