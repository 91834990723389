import React, { Component } from "react";
import { reformatTimestamp } from "../../../../util/functions";

// Components
import SraDialog from "./SraDialog";
import RemoveDialog from "../../../global/RemoveDialog";

// MUI Stuff
import Button from "@material-ui/core/Button";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

// Images
import UpdateIcon from "../../../../img/icon-timer.svg";
import RemoveIcon from "../../../../img/icon-circle-x.svg";

class ServiceSraRow extends Component {
  state = {
    removeOpen: false,
    viewOpen: false
  };
  handleRemove = () => {
    this.setState({ removeOpen: true });
  };
  handleRemoveClose = () => {
    this.setState({ removeOpen: false });
  };
  handleUpdate = () => {
    this.setState({ viewOpen: true });
  };
  handleUpdateClose = () => {
    this.setState({ viewOpen: false });
  };
  render() {
    const { singleRow, rowId, collection, permission } = this.props;
    return (
      <TableRow>
        <TableCell>
          <Button className="expand" onClick={this.handleUpdate}>
            {singleRow.sra}
          </Button>
        </TableCell>
        <TableCell>{reformatTimestamp(singleRow.date.seconds)}</TableCell>
        {permission !== "Service Rep" && (
          <TableCell>{singleRow.userName}</TableCell>
        )}
        {permission === "Admin" && (
          <TableCell>{singleRow.userRegion}</TableCell>
        )}
        <TableCell>{singleRow.status}</TableCell>
        {permission !== "Service Rep" && (
          <TableCell className="actions">
            <Button
              className="update"
              variant="contained"
              color="primary"
              onClick={this.handleUpdate}
            >
              <img src={UpdateIcon} alt="icon" />
              Update
            </Button>
            <Button
              className="remove"
              variant="contained"
              color="primary"
              onClick={this.handleRemove}
            >
              <img src={RemoveIcon} alt="icon" />
              Remove
            </Button>
          </TableCell>
        )}
        <SraDialog
          singleRow={singleRow}
          rowId={rowId}
          open={this.state.viewOpen}
          handleClose={this.handleUpdateClose}
          collection={collection}
          permission={permission}
        />
        <RemoveDialog
          open={this.state.removeOpen}
          handleClose={this.handleRemoveClose}
          docId={rowId}
          name={singleRow.sra}
          collection={collection}
        />
      </TableRow>
    );
  }
}

export default ServiceSraRow;
