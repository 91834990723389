import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { isLoaded, isEmpty } from "react-redux-firebase";

// Components
import LoadingBlock from "../../global/LoadingBlock";
import ServiceTitleBar from "../../global/ServiceTitleBar";
import ServiceInventoryTable from "./components/ServiceInventoryTable";
import ServiceInventoryTabs from "./components/ServiceInventoryTabs";

class serviceInventoryActive extends Component {
  state = {
    perPage: 999
  };
  showMore = current => {
    let more = Number(current) + 50;
    this.setState({ perPage: more });
  };
  render() {
    const { auth, profile } = this.props;
    // If user isn't logged in
    if (isEmpty(auth)) {
      return <Redirect to="/login" />;
    }
    // If everything went to plan
    if (profile.isLoaded) {
      let firestoreQuery;
      if (profile.permission === "Service Rep") {
        firestoreQuery = [
          [
            "status",
            "in",
            [
              "Requested",
              "Denied",
              "Approved",
              "Need More Info",
              "In Progress",
              "Backordered",
              "Shipped"
            ]
          ],
          ["user", "==", auth.uid]
        ];
      }
      if (profile.permission === "Manager") {
        firestoreQuery = [
          [
            "status",
            "in",
            [
              "Requested",
              "Denied",
              "Approved",
              "Need More Info",
              "In Progress",
              "Backordered",
              "Shipped"
            ]
          ],
          ["userRegion", "==", profile.region]
        ];
      }
      if (profile.permission === "Admin") {
        firestoreQuery = [
          "status",
          "in",
          [
            "Requested",
            "Denied",
            "Approved",
            "Need More Info",
            "In Progress",
            "Backordered",
            "Shipped"
          ]
        ];
      }
      return (
        <div>
          <ServiceTitleBar />
          <h1>Active Inventory Requests</h1>
          <ServiceInventoryTable
            permission={profile.permission}
            perPage={this.state.perPage}
            showMore={this.showMore}
            user={auth.uid}
            firestoreQuery={firestoreQuery}
          />
          <ServiceInventoryTabs permission={profile.permission} />
        </div>
      );
    }
    // If profile and auth aren't loaded
    return <LoadingBlock />;
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile
  };
};

export default connect(mapStateToProps, null)(serviceInventoryActive);
