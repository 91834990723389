import React, { Component } from "react";

// Components
import RemoveDialog from "./RemoveDialog";

// MUI Stuff
import Button from "@material-ui/core/Button";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

// Images
import RemoveIcon from "../../img/icon-circle-x.svg";

class FileListTableRow extends Component {
  state = {
    removeOpen: false
  };
  handleRemove = () => {
    this.setState({ removeOpen: true });
  };
  handleRemoveClose = () => {
    this.setState({ removeOpen: false });
  };
  render() {
    const { singleRow, rowId, collection, permission } = this.props;
    return (
      <TableRow>
        <TableCell>
          <Button className="file" href={singleRow.fileURL} target="_blank">
            {singleRow.name}
          </Button>
        </TableCell>
        {permission === "Admin" && (
          <TableCell className="actions">
            <Button
              className="remove"
              variant="contained"
              color="primary"
              onClick={this.handleRemove}
            >
              <img src={RemoveIcon} alt="icon" />
              Remove
            </Button>
          </TableCell>
        )}
        {permission === "Admin" && (
          <RemoveDialog
            open={this.state.removeOpen}
            handleClose={this.handleRemoveClose}
            collection={collection}
            docId={rowId}
            name={singleRow.name}
          />
        )}
      </TableRow>
    );
  }
}

export default FileListTableRow;
