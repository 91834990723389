import React, { Component } from "react";
import { connect } from "react-redux";
import { reformatTimestamp } from "../../../../util/functions";
import { withRouter } from "react-router-dom";

// Actions
import { updateInventory } from "../../../../store/actions/inventoryActions";

// MUI Stuff
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";

class ServiceInventorySingleEdit extends Component {
  state = {
    id: this.props.rowId,
    status: this.props.singleRow.status,
    info: ""
  };
  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };
  handleSelectChange = name => e => {
    this.setState({
      [name]: e.target.value
    });
  };
  handleSave = () => {
    this.props.updateInventory(this.state, this.props.collection);
    this.props.history.push("/service/inventory/active");
  };
  render() {
    const { singleRow, rowId, permission, history } = this.props;
    return (
      <div className="dialog--single">
        <div className="update">
          <div className="update--column">
            <h2>Order Information</h2>
            {permission === "Admin" || permission === "Manager" ? (
              <div className="status">
                <FormControl variant="filled">
                  <InputLabel id="status-label">Status</InputLabel>
                  <Select
                    id="status"
                    labelId="status-label"
                    variant="filled"
                    label="Status"
                    value={this.state.status}
                    onChange={this.handleSelectChange("status")}
                  >
                    <MenuItem value="Requested">Requested</MenuItem>
                    <MenuItem value="Denied">Denied</MenuItem>
                    <MenuItem value="Approved">Approved</MenuItem>
                    <MenuItem value="Need More Info">Need More Info</MenuItem>
                    <MenuItem value="In Progress">In Progress</MenuItem>
                    <MenuItem value="Backordered">Backordered</MenuItem>
                    <MenuItem value="Shipped">Shipped</MenuItem>
                    <MenuItem value="Complete">Complete</MenuItem>
                    <MenuItem value="Cancelled">Cancelled</MenuItem>
                  </Select>
                </FormControl>
                {this.state.status === "Need More Info" && (
                  <TextField
                    id="info"
                    label="Information Needed"
                    variant="filled"
                    multiline={true}
                    rows={5}
                    rowsMax={5}
                    onChange={this.handleChange}
                    defaultValue={singleRow.info}
                  ></TextField>
                )}
              </div>
            ) : (
              <div>
                {this.state.status === "Shipped" ||
                this.state.status === "Complete" ? (
                  <div className="status">
                    <FormControl variant="filled">
                      <InputLabel id="status-label">Status</InputLabel>
                      <Select
                        id="status"
                        labelId="status-label"
                        variant="filled"
                        label="Status"
                        value={this.state.status}
                        onChange={this.handleSelectChange("status")}
                      >
                        <MenuItem value="Shipped">Shipped</MenuItem>
                        <MenuItem value="Complete">Received</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                ) : (
                  <div>
                    <h5>Status</h5>
                    <p>{singleRow.status}</p>
                  </div>
                )}
              </div>
            )}
            {permission !== "Admin" &&
              singleRow.info &&
              this.state.status === "Need More Info" && (
                <div>
                  <h5 className="red">Information Needed:</h5>
                  <p className="red">{singleRow.info}</p>
                </div>
              )}
            <h5>Date</h5>
            <p>{reformatTimestamp(singleRow.date.seconds)}</p>
            <h5>Ordered By</h5>
            <p>{singleRow.userName}</p>
            <h5>Region</h5>
            <p>{singleRow.userRegion}</p>
            <h5>Make</h5>
            <p>{singleRow.make}</p>
            <h5>Model</h5>
            <p>{singleRow.model}</p>
            {singleRow.part && (
              <div>
                <h5>Part #</h5>
                <p>{singleRow.part}</p>
              </div>
            )}
            <h5>Description</h5>
            <p>{singleRow.description}</p>
            {singleRow.photo[0] && (
              <div>
                <h5>Part Photo(s)</h5>
                <div className="update--images">
                  {singleRow.photo.map(singlePhoto => {
                    return (
                      <a
                        href={singlePhoto}
                        className="update--image"
                        target="_blank"
                        rel="noopener noreferrer"
                        key={singlePhoto}
                      >
                        <img src={singlePhoto} alt="Part Reference" />
                      </a>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
          <div className="update--column">
            <h2>Shipping Information</h2>
            <h5>Shipping Speed</h5>
            <p>{singleRow.shippingspeed}</p>
            <h5>Facility Name</h5>
            <p>{singleRow.facilityName}</p>
            {singleRow.billingAccountNumber && (
              <div>
                <h5>Billing Account #</h5>
                <p>{singleRow.billingAccountNumber}</p>
              </div>
            )}
            <h5>Phone</h5>
            <p>{singleRow.phoneNumber}</p>
            <h5>Email</h5>
            <p>{singleRow.email}</p>
            {singleRow.shippingAccountNumber && (
              <div>
                <h5>Shipping Account #</h5>
                <p>{singleRow.shippingAccountNumber}</p>
              </div>
            )}
            {singleRow.shipto === true && (
              <div>
                <h5>Ship to Rep's Home Address</h5>
                <p>
                  {singleRow.userAddress}
                  <br />
                  {singleRow.userCity}
                  {", "}
                  {singleRow.userState} {singleRow.userZip}
                </p>
              </div>
            )}
            {singleRow.shippingAddress && (
              <div>
                <h5>Ship to</h5>
                <p>
                  {singleRow.shippingAddress}
                  <br />
                  {singleRow.shippingCity}
                  {", "}
                  {singleRow.shippingState} {singleRow.shippingZip}
                </p>
              </div>
            )}
          </div>
        </div>
        <Button variant="contained" color="primary" onClick={this.handleSave}>
          Update Request
        </Button>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateInventory: (data, collection) =>
      dispatch(updateInventory(data, collection))
  };
};

export default withRouter(
  connect(null, mapDispatchToProps)(ServiceInventorySingleEdit)
);
