import React, { Component } from "react";

// MUI Stuff
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

class UsersRemoveDialog extends Component {
  handleRemove = () => {
    this.props.handleClose();
    this.props.handleDisable();
  };

  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose}
        className="dialog--remove"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to disable the account for {this.props.name}?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This will keep their user data, but disable their account.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleClose}>Keep</Button>
          <Button onClick={this.handleRemove} color="primary" autoFocus>
            Disable
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default UsersRemoveDialog;
