import React from "react";
import { CSVLink, CSVDownload } from "react-csv";
import { reformatTimestamp } from "../../../../util/functions";

// Components
const ServiceInventoryExportCsv = ({ inventory }) => {
  const csvData = [
    [
      "Date",
      "Status",
      "Region",
      "Ordered By",
      "Make",
      "Model",
      "Part",
      "Description",
      "Facility Name",
      "Billing Acct",
      "Ship To Rep",
      "Shipping Acct",
      "Shipping Speed",
      "Shipping Address"
    ],
    ...inventory.map(item => {
      let billingAccountNumber = "";
      if (item.billingAccountNumber) {
        billingAccountNumber = item.billingAccountNumber;
      }

      let address =
        item.shippingAddress +
        ", " +
        item.shippingCity +
        ", " +
        item.shippingState +
        " " +
        item.shippingZip;
      if (item.shipto) {
        address =
          item.userAddress +
          ", " +
          item.userCity +
          ", " +
          item.userState +
          " " +
          item.userZip;
      }
      return [
        reformatTimestamp(item.date.seconds),
        item.status,
        item.userRegion,
        item.userName,
        item.make,
        item.model,
        item.part,
        item.description,
        item.facilityName,
        billingAccountNumber,
        item.shipto,
        item.shippingAccountNumber,
        item.shippingspeed,
        address
      ];
    })
  ];

  return (
    <div>
      <CSVLink
        data={csvData}
        className="button"
        filename={"active-inventory.csv"}
        style={{
          marginTop: 0,
          marginBottom: 8,
          fontSize: 15,
          paddingTop: 8,
          paddingBottom: 8,
          paddingLeft: 16,
          paddingRight: 16
        }}
      >
        Export All to CSV
      </CSVLink>
    </div>
  );
};

export default ServiceInventoryExportCsv;
