import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { isLoaded, isEmpty } from "react-redux-firebase";

// Components
import LoadingBlock from "../../global/LoadingBlock";
import ServiceTitleBar from "../../global/ServiceTitleBar";
import ServiceSraTable from "./components/ServiceSraTable";
import ServiceSraTabs from "./components/ServiceSraTabs";

class serviceSraArchive extends Component {
  state = {
    perPage: 50
  };
  showMore = current => {
    let more = Number(current) + 50;
    this.setState({ perPage: more });
  };
  render() {
    const { auth, profile } = this.props;
    // If user isn't logged in
    if (isEmpty(auth)) {
      return <Redirect to="/login" />;
    }
    // If everything went to plan
    if (profile.isLoaded) {
      let firestoreQuery;
      if (profile.permission === "Service Rep") {
        firestoreQuery = [
          ["status", "in", ["Denied", "Cancelled", "Closed"]],
          ["user", "==", auth.uid]
        ];
      }
      if (profile.permission === "Manager") {
        firestoreQuery = [
          ["status", "in", ["Denied", "Cancelled", "Closed"]],
          ["userRegion", "==", profile.region]
        ];
      }
      if (profile.permission === "Admin") {
        firestoreQuery = ["status", "in", ["Denied", "Cancelled", "Closed"]];
      }
      return (
        <div>
          <ServiceTitleBar />
          <h1>Archived SRA Requests</h1>
          <ServiceSraTable
            permission={profile.permission}
            perPage={this.state.perPage}
            showMore={this.showMore}
            user={auth.uid}
            firestoreQuery={firestoreQuery}
          />
          <ServiceSraTabs permission={profile.permission} />
        </div>
      );
    }
    // If profile and auth aren't loaded
    return <LoadingBlock />;
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile
  };
};

export default connect(
  mapStateToProps,
  null
)(serviceSraArchive);
